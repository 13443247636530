import { useAppTheme } from 'src/theme/theme';
import React from 'react';
import { ConnectionLineComponentProps } from 'reactflow';
import { getCustomBezierPath } from 'src/util/helpers/geometry';

const ConnectionLine: React.FC<ConnectionLineComponentProps> = (props) => {
  const { fromPosition, fromX, fromY, toX, toY, toPosition } = props;
  if (!fromPosition) return null;

  const { palette } = useAppTheme();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [curve, d] = getCustomBezierPath({
    sourceX: fromX,
    sourceY: fromY,
    sourcePosition: fromPosition,
    targetX: toX,
    targetY: toY,
    targetPosition: toPosition
  });
  return (
    <g>
      <path
        fill="none"
        stroke={palette.primary.dark}
        strokeWidth={1.5}
        className="animated"
        // d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
        d={curve}
      />
      <circle cx={toX} cy={toY} fill="#fff" r={3} stroke={palette.primary.dark} strokeWidth={1.5} />
      {/* COR For debugging bezier curves */}
      {/* <circle cx={d.firstBezier.x} cy={d.firstBezier.y} fill="#F00" r={3} stroke="#F00" strokeWidth={1.5} />
      <circle cx={d.secondBezier.x} cy={d.secondBezier.y} fill="#0F0" r={3} stroke="#0F0" strokeWidth={1.5} /> */}
    </g>
  );
};

export default ConnectionLine;
