import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  onSnapshot
} from 'firebase/firestore';
import { DependencyList } from 'react';
import { Nilable } from 'tsdef';
import useEffectDiff from './useEffectDiff';
import useStateReducer from './useStateReducer';

type StateType<T> = {
  lastDocument: QueryDocumentSnapshot<T> | null;
  isLoading: boolean;
  count: number;
  data: QueryDocumentSnapshot<T>[] | null;
};
const initialStateData: StateType<never> = {
  lastDocument: null,
  isLoading: false,
  count: 0,
  data: null
};
/**
 * This hook will manage data pagination for the given query.
 *
 * If the dataQuery, column or columnOrder changes, onPageChange will be triggered, setting the
 * selected page to 0. This happens to avoid inconsistent states when the fetched data amount is not
 * enough to fill the data shown by the selected page.
 *
 * The data query must not use different columns for filtering and sorting.
 *
 * @param extraResetDeps parameters that should cause the query to reset as well
 */
const usePaginatedData = <T>(
  dataQuery: Query<T> | null,
  page: number,
  pageSize: number,
  orderByColumn: Nilable<string>,
  columnOrder: Nilable<'asc' | 'desc'>,
  onPageChange: (page: number) => void,
  isDataTable = false,
  extraResetDeps: DependencyList = [],
  useListener?: boolean
) => {
  const [state, setState] = useStateReducer<StateType<T>>({ ...initialStateData });

  const checkData = (reset: boolean) => {
    const fetchedDataCount = reset ? 0 : state.data?.length ?? 0;
    if (!!dataQuery && fetchedDataCount < pageSize * (isDataTable ? page + 1 : page)) {
      let currentDataQuery = query(dataQuery, limit(pageSize));
      // In case the user order by column
      if (columnOrder && orderByColumn) {
        currentDataQuery = query(currentDataQuery, orderBy(orderByColumn, columnOrder));
      }
      // If the query has already been made, start from the last element seen
      if (!reset && state.lastDocument) {
        currentDataQuery = query(currentDataQuery, startAfter(state.lastDocument));
      }

      setState({ isLoading: true });

      if (useListener) {
        onSnapshot(currentDataQuery, {
          next: (snap) => {
            // Fetch the count of documents from the server
            getCountFromServer(dataQuery)
              .then((countSnap) => {
                const count = countSnap.data().count;

                // Update the state with the new data and count
                setState({
                  data: [...(reset ? [] : state.data ?? []), ...snap.docs],
                  isLoading: false,
                  count: count,
                  lastDocument: snap.docs[snap.docs.length - 1]
                });
              })
              .catch((error: Error) => {
                console.error(`Failed fetching count: ${error.message}`);
              });
          },
          error: (error) => console.error(`Failed fetching data: ${error.message}`)
        });
      } else {
        getCountFromServer(dataQuery)
          .then(async (countSnap) => {
            const count = countSnap.data().count;
            // Total count returned 0, meaning the query will be empty, there's no need to fetch it.
            if (count === 0) {
              setState({ ...initialStateData });
              return;
            }

            const _data = await getDocs(currentDataQuery);
            setState({
              data: [...(reset ? [] : state.data ?? []), ..._data.docs],
              isLoading: false,
              count: countSnap.data().count,
              lastDocument: _data.docs[_data.docs.length - 1]
            });

            if (reset) onPageChange(isDataTable ? 0 : 1);
          })
          .catch((err) => {
            console.error('Failed counting docs: ', err);
            setState({ ...initialStateData });
          });
      }
    }
  };

  useEffectDiff(
    (diff) => {
      const changes = Object.keys(diff);
      if (changes.length) {
        // page or pageSize doesn't trigger reset, but all other params do,
        // so  changes to indexes >= 2 (columOrder) should trigger reset
        const reset = parseInt(changes.sort().reverse()[0] ?? 0) >= 2;
        checkData(reset);
      }
    },
    [page, pageSize, columnOrder, orderByColumn, dataQuery, JSON.stringify(extraResetDeps)]
  );

  return {
    isLoading: state.isLoading,
    data: state.data,
    count: state.count
  };
};

export default usePaginatedData;
