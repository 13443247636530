import { AnyAction, combineReducers } from 'redux';
import acquisitionPicsReducer from './acquisitionPicsReducer';
import acquisitionReducer from './acquisitionReducer';
import firestoreReducer from './firestoreReducer';
import globalsReducer from './globalsReducer';
import labelImagesReducer from './labelImagesReducer';
import localeReducer from './localeReducer';
import processFlowSlice from './processFlowReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';

// COR - Set to false
const debugRedux = false;
const logger =
  (tag: string = '') =>
  (_state: any, action: AnyAction) => {
    if (debugRedux) {
      console.log(tag, action.type, action.payload);
    }
    return null;
  };

const combinedReducers = combineReducers({
  actionLogger: logger('Incoming action'), // COR
  // Contains data related to the currently logged in user, including profile pictures
  user: userReducer,
  // Contains states related to global ui feedback
  ui: uiReducer,
  // Contains locale and translation data
  locale: localeReducer,
  // Contains state and data of label pictures
  labelImages: labelImagesReducer,
  // Contains state and data of acquisition-related pictures
  acqPictures: acquisitionPicsReducer,
  // Contains data related to process flow building process (UI-related)
  processFlow: processFlowSlice,
  // Contains data related to acquisition, like sessions and tasks.
  acquisitionData: acquisitionReducer,
  // Contains general state values that require sharing along many components
  globals: globalsReducer,
  // Contains firestore data for child users, domains and owned data, when user is Mod or KeyUser
  firestore: firestoreReducer
});

export default combinedReducers;
