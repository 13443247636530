import { KeyUserPublic, UserPublic } from 'flyid-core/dist/Database/Models/User';
import {
  isNulli,
  sortObjectByValueSelector,
  getFirstKey,
  getFirst
} from 'flyid-core/dist/Util/helpers';
import { getStoredOrDefault } from 'flyid-core/dist/Util/web';
import { useMemo } from 'react';
import { filterModerators, isKeyUserProf } from 'src/util/helpers/user';
import { getGlobalStorageKey, GlobalStateKey } from '../reducers/globalsReducer';
import { AppState } from '../store';
import { UserProfile } from 'firebase/auth';

export const selectCurrentUser = (state: AppState) =>
  state.user.isLoaded ? state.user : undefined;

export const selectCurrentUserProfile = (state: AppState) => selectCurrentUser(state)?.profile;

export const selectUserProfile = (state: AppState, uid: string) => selectUserProfiles(state)?.[uid];

export const selectUserProfiles = (state: AppState, sortByName = false) => {
  if (!sortByName) {
    return state.firestore.userProfiles;
  } else {
    const users = state.firestore.userProfiles;
    return useMemo(
      () => (users ? sortObjectByValueSelector(users, (u) => u.firstName + u.lastName) : undefined),
      [users]
    );
  }
};

export const selectUserProfilePics = (state: AppState) => state.user.profilePics;

export const selectUserProfileThumbs = (state: AppState) => state.user.profilePicThumbs;

export const selectTargetCompany = (state: AppState) => {
  const profile = selectCurrentUserProfile(state);
  if (isNulli(profile)) return undefined;

  return isKeyUserProf(profile)
    ? state.globals.targetCompany ?? getGlobalDefault('targetCompany', state)
    : (profile as UserPublic).company;
};

/**
 * Selects the user's authDomains for the given company parameter.
 * If no company parameter is given, defaults to the value provided by {@link selectTargetCompany}
 */
type SelectAuthDomainsExtraArgs = {
  company?: string;
  targetUser?: string;
};
export const selectAuthDomains = (state: AppState, extraArgs?: SelectAuthDomainsExtraArgs) => {
  const { company = selectTargetCompany(state), targetUser = undefined } = extraArgs ?? {};

  const profile = targetUser
    ? selectUserProfile(state, targetUser)
    : selectCurrentUserProfile(state);
  if (isNulli(profile)) return undefined;

  return isKeyUserProf(profile) && company
    ? state.firestore.authDomains?.[company] ?? []
    : (profile as UserPublic).authDomains;
};

export const selectTargetParentUid = (state: AppState) => {
  const profile = selectCurrentUserProfile(state);
  if (isNulli(profile)) return undefined;

  return isKeyUserProf(profile)
    ? state.globals.targetParentUid ?? getGlobalDefault('targetParentUid', state)
    : '';
};

export const selectKeyUserCompanies = (state: AppState, ordered = true) => {
  const profile = selectCurrentUserProfile(state);
  if (isNulli(profile) || !isKeyUserProf(profile)) return undefined;

  const companies = (profile as KeyUserPublic).company;
  return ordered ? [...companies].sort() : companies;
};

export const selectDomainParent = (state: AppState, domain: string) => {
  const currProfile = selectCurrentUser(state);
  // If current user is not key user, it must be the moderator which parents this domain.
  if (!currProfile?.profile?.keyUser) return currProfile?.uid;

  // If key user, then we have to select the correct parent and pass it down to other components
  const targetCompany = selectTargetCompany(state);

  if (targetCompany) {
    const selectionData = selectUserProfiles(state, false);
    const parentCandidates = filterModerators(
      selectionData,
      (uid, p) => p.company === targetCompany && p.authDomains && p.authDomains.includes(domain)
    );
    if (parentCandidates) {
      // It should only have one, but can fail if database has some sort of inconsistency.
      if (parentCandidates.length > 1)
        console.error('More than one parent found for this domain!!');
      return Object.keys(parentCandidates[0] ?? {})[0];
    }
  }

  return undefined;
};

/**
 * Retrieves the default value for global states.
 *
 * It is on userSelectors.ts to avoid cyclic dependencies, maybe deservers a file for itself or defaults?
 */
export const getGlobalDefault = (key: GlobalStateKey, state: AppState) => {
  switch (key) {
    case 'targetCompany':
      return getStoredOrDefault(getGlobalStorageKey(key), selectKeyUserCompanies(state)?.[0]);
    case 'targetParentUid':
      return getStoredOrDefault(getGlobalStorageKey(key), '');
    default:
      throw Error('Should never happen!');
  }
};
