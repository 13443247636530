"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateParentDimensions = exports.getCurrentParentDimensions = exports.getOutputNodePosition = exports.getNewChildPosition = exports.PARENT_HORIZONTAL_PADDING = exports.PARENT_VERTICAL_START = exports.PARENT_VERTICAL_SPACING = exports.PARENT_TITLE_HEIGHT_PX = exports.ExpectedSizes = exports.createEdgeBetween = exports.generateDoubleId = exports.generateId = exports.getTypedElementId = exports.getTypedId = void 0;
const Elements_1 = require("../Database/Models/Settings/ProcessFlow/Elements");
/** Get node id in format NodeType_RandomizedId */
const getTypedId = (node) => `${node.type}_${node.id}`;
exports.getTypedId = getTypedId;
const getTypedElementId = (id, type) => `${type}_${id}`;
exports.getTypedElementId = getTypedElementId;
const generateId = () => Math.random().toString(36).slice(2);
exports.generateId = generateId;
const generateDoubleId = () => (0, exports.generateId)().concat((0, exports.generateId)());
exports.generateDoubleId = generateDoubleId;
const createEdgeBetween = (sourceNodeId, targetNodeId, sourceHandle = `${Elements_1.HandleType.SINGLE}_0`, targetHandle = `${Elements_1.HandleType.MULTIPLE}_0`) => {
    return {
        source: sourceNodeId,
        target: targetNodeId,
        sourceHandle,
        targetHandle
    };
};
exports.createEdgeBetween = createEdgeBetween;
/** Used both in front end sizing and backend migration scripts */
exports.ExpectedSizes = {
    [Elements_1.NodeType.Start]: { width: 118, height: 44 },
    [Elements_1.NodeType.LabelDesign]: { width: 174, height: 77 },
    [Elements_1.NodeType.ManualInputField]: { width: 152, height: 77 },
    [Elements_1.NodeType.TakePicture]: { width: 156, height: 77 },
    [Elements_1.NodeType.AutoFillData]: { width: 156, height: 44 },
    [Elements_1.NodeType.Conditional]: { width: 156, height: 48 },
    [Elements_1.NodeType.LogicalBlock]: { width: 174, height: 140 },
    [Elements_1.NodeType.CustomMarker]: { width: 203, height: 140 },
    [Elements_1.NodeType.End]: { width: 118, height: 44 }
};
/****************************************
 **         PARENT NODE DATA           **
 **                                    **
 **  These are used both on front      **
 **  end, defining widget positioning  **
 **  and back end settings migration   **
 ****************************************/
exports.PARENT_TITLE_HEIGHT_PX = 24;
exports.PARENT_VERTICAL_SPACING = 16;
exports.PARENT_VERTICAL_START = exports.PARENT_TITLE_HEIGHT_PX + exports.PARENT_VERTICAL_SPACING;
exports.PARENT_HORIZONTAL_PADDING = 24;
const getNewChildPosition = (newNode, index, parentWidth, outputSiblingNode) => {
    var _a, _b, _c;
    const contentParentWidth = parentWidth -
        (outputSiblingNode ? 2 * exports.PARENT_HORIZONTAL_PADDING + ((_a = outputSiblingNode.width) !== null && _a !== void 0 ? _a : 130) / 3 : 0);
    return {
        x: (contentParentWidth - ((_b = newNode.width) !== null && _b !== void 0 ? _b : 190)) / 2,
        y: exports.PARENT_VERTICAL_START + index * (((_c = newNode.height) !== null && _c !== void 0 ? _c : 77) + exports.PARENT_VERTICAL_SPACING)
    };
};
exports.getNewChildPosition = getNewChildPosition;
const getOutputNodePosition = (node, parentWidth, parentHeight) => {
    var _a, _b;
    return {
        x: parentWidth - ((_a = node.width) !== null && _a !== void 0 ? _a : 240) / 3,
        y: (parentHeight + exports.PARENT_TITLE_HEIGHT_PX - ((_b = node.height) !== null && _b !== void 0 ? _b : 100)) / 2
    };
};
exports.getOutputNodePosition = getOutputNodePosition;
const calculateParentWidth = (contentChildren, outputChild) => {
    var _a;
    let width = 0;
    contentChildren.forEach((n) => {
        var _a;
        width = Math.max((_a = n.width) !== null && _a !== void 0 ? _a : 100, width);
    });
    // If there's an output node, add some padding as space for new edges
    if (outputChild)
        width += 1 * exports.PARENT_HORIZONTAL_PADDING + ((_a = outputChild.width) !== null && _a !== void 0 ? _a : 100) / 3;
    width += 2 * exports.PARENT_HORIZONTAL_PADDING;
    return width;
};
const calculateParentHeight = (contentChildren, outputChild) => {
    let height = exports.PARENT_VERTICAL_START;
    contentChildren.forEach((n) => {
        var _a;
        height += ((_a = n.height) !== null && _a !== void 0 ? _a : 30) + exports.PARENT_VERTICAL_SPACING;
    });
    return height;
};
const getCurrentParentDimensions = (parentRef) => {
    var _a, _b;
    if (((_a = parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) && parentRef.current.clientHeight) {
        return { width: (_b = parentRef === null || parentRef === void 0 ? void 0 : parentRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth, height: parentRef.current.clientHeight };
    }
    return undefined;
};
exports.getCurrentParentDimensions = getCurrentParentDimensions;
const calculateParentDimensions = (contentChildren, outputChild) => {
    if (!contentChildren.length)
        return undefined;
    return {
        width: calculateParentWidth(contentChildren, outputChild),
        height: calculateParentHeight(contentChildren, outputChild)
    };
};
exports.calculateParentDimensions = calculateParentDimensions;
