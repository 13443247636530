import AssignmentIcon from '@mui/icons-material/Assignment';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ListIcon from '@mui/icons-material/List';
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { saveAs } from 'file-saver';
import { query } from 'firebase/firestore';
import { Constants } from 'flyid-core/dist/Common';
import {
  DomainSettings,
  ReviewState,
  Session,
  StandardFlags
} from 'flyid-core/dist/Database/Models';
import { getSessionsCol } from 'flyid-core/dist/Util/database';
import { parseDateFromTimestamp } from 'flyid-core/dist/Util/time';
import { storeEventCheck, storeEventValue } from 'flyid-core/dist/Util/web';
import usePaginatedData from 'flyid-ui-components/dist/hooks/usePaginatedData';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import JSZip from 'jszip';
import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { selectCompaniesData, selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectCurrentUserProfile, selectTargetCompany } from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { getXlsx } from 'src/util/lazyLoaders';
import { TranslatableError } from 'src/util/locale';
import { Actions } from '../../redux/actions/actionsHandler';
import { MyDialogState, updateUi } from '../../redux/reducers/uiReducer';
import {
  excelTabInvalidChars,
  getHeaderCellsFromObject,
  GetSessionDataResult,
  HeaderCellObject,
  Order,
  STANDARD_FLAGS_COLUMN,
  TableColumns,
  TableRow,
  TableRows
} from '../../util/helpers/table';
import { getSessionData } from '../../workers/dataWorkerApi';
import EnhancedTable, { OrderChangeCallback } from './EnhancedTable/EnhancedTable';

const useStyles = appMakeStyles((theme) => ({
  container: { ...theme.resizableContainer(2) },
  margin: {
    marginBottom: theme.spacing(2)
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

type SessionRow = Session & {
  name: string;
  taskReport: string;
  sessionReview: string;
};
type SessionKey = keyof SessionRow;
const headerFields: SessionKey[] = [
  'name',
  'startTime',
  'total',
  'empty',
  'invalid',
  'endTime',
  'taskReport',
  'sessionReview'
];

const OUTPUT_TYPES = {
  XLSX_TASK_PER_TAB: 'XLSX_TASK_PER_TAB',
  XLSX_TASK_PER_FILE: 'XLSX_TASK_PER_FILE',
  CSV_TASK_PER_FILE: 'CSV_TASK_PER_FILE'
};

type EnhancedPageState = {
  rowsAndColumns: { rows: TableRows; columns: TableColumns } | TranslatableError | undefined;
  total: number;
  page: number;
  pageSize: number;
};

const Domain: React.FC = () => {
  const { domain } = useParams<DomainMatchParams>();
  const theme = useAppTheme();
  const classes = useStyles();
  const { $t } = useIntl();

  const dispatch = useAppDispatch();
  const { profile, settings, company, canTriggerSessionPush, companyData } = useAppSelector(
    (state) => {
      const profile = selectCurrentUserProfile(state);
      const canTriggerSessionPush = !!profile?.assistant || !!profile?.moderator;
      const company = selectTargetCompany(state);
      const companyData = selectCompaniesData(state)?.[company ?? ''];

      return {
        profile,
        canTriggerSessionPush,
        company,
        companyData,
        // sessions: selectSessions(domain, state),
        settings: selectSettings(state, domain)
      };
    }
  );

  const [order, setOrder] = useStoredState<Order>('domainOrder', 'asc');
  const [orderBy, setOrderBy] = useStoredState<string | null>('domainOrderBy', null);
  const [mergeLabels, setMergeLabels] = useStoredState('exportMergeLabels', true);
  const [showInputData, setShowInputData] = useStoredState('exportShowInputData', false);
  const [showBaseFields, setShowBaseFields] = useStoredState('exportShowBaseFields', true);
  const [outputType, setOutputType] = useStoredState(
    'exportOutputType',
    OUTPUT_TYPES.XLSX_TASK_PER_TAB
  );

  const [pageState, setPageState] = useStateReducer<EnhancedPageState>({
    rowsAndColumns: undefined,
    total: 0,
    page: 0,
    pageSize: 10
  });

  const resetPageState = () => {
    setPageState({
      rowsAndColumns: undefined,
      total: 0,
      page: 0,
      pageSize: 10
    });
  };

  const dataQuery = useMemo(() => {
    resetPageState();
    if (!profile) return null;
    return query(buildCollectionRef(getSessionsCol(company!, domain)));
  }, [profile, domain]);

  const [sessionsQS] = useCollection<Session>(
    profile && query(buildCollectionRef(getSessionsCol(company!, domain)))
  );

  const sessions = querySnapToMap(sessionsQS);

  const [selected, setSelected] = useState<string[]>([]);
  const [showDialog, setShowDialog] = useState(false);

  const onTriggerSessionPushClick = (session: TableRow) => {
    if (session.pushStatus !== 'FAILED') return;

    // Get epoch of next full hour time
    const ref = new Date();
    ref.setHours(ref.getHours() + 1, 0, 0);
    // Calculate the number of minutes until next full hour
    const minToRetry = Math.round((ref.getTime() - Date.now()) / 1000 / 60);

    dispatch(
      updateUi({
        dialog: {
          title: $t({ id: 'domain.triggerSessionPushTitle' }),
          message: $t({ id: 'domain.triggerSessionPushMsg' }, { minToRetry }),
          confirmAction: Actions.TRIGGER_SESSION_PUSH,
          confirmActionData: { domain, session: [session.name as string] },
          show: true
        }
      })
    );
  };

  const onRemoveSessionsClick =
    (selectedSessions: string[]) => (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (profile && company) {
        const dialog = new MyDialogState({
          title: $t({ id: 'domain.remSessionsTitle' }),
          message: $t({ id: 'domain.remSessionsMsg' }),
          checkboxMessage: $t({ id: 'domain.remSessionsCheckbox' }),
          useCheckbox: true,
          show: true
        }).setConfirmAction(Actions.REMOVE_SESSIONS, {
          company,
          domain,
          session: selectedSessions
        });

        dispatch(updateUi({ dialog }));
      }
    };

  const onOrderChange: OrderChangeCallback = (_order, _orderBy) => {
    setOrder(_order);
    setOrderBy(_orderBy);
  };

  const leftAlignedButtons = (
    <Button
      disableElevation
      size="medium"
      aria-label={$t({ id: 'domain.inventoryTasks' })}
      component={Link}
      to={`/domains/${domain}/tasks`}
    >
      <ListIcon sx={{ mr: 1 }} />
      {$t({ id: 'domain.inventoryTasks' })}
    </Button>
  );

  const missingDataAlternative = () => {
    const path = `/domains/${domain}/tasks`;
    return (
      <Typography variant="h6">
        {$t(
          { id: 'domain.missingDataText' },
          {
            domain,
            btn: (
              <Button component={Link} to={path} size="small" key={path}>
                {$t({ id: 'clickHere' })}
              </Button>
            )
          }
        )}
      </Typography>
    );
  };

  const showPreparationBackdrop = () =>
    dispatch(
      updateUi({
        backdrop: {
          show: true,
          message: { msgCode: 'domain.preparingFiles', msg: 'Preparing file(s) for download...' }
        }
      })
    );

  const hidePreparationBackdrop = () => dispatch(updateUi({ backdrop: { show: false } }));

  const renderDialog = () => (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{$t({ id: 'domain.exportTitle' })}</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>{$t({ id: 'domain.selectExportOptions' })}</Box>
        {renderExportOptions()}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowDialog(false)} color="primary" autoFocus>
          {$t({ id: 'cancel' })}
        </Button>
        <Button
          onClick={() => {
            setShowDialog(false);
            exportSessions();
          }}
          color="primary"
        >
          {$t({ id: 'confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderExportOptions = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        select
        fullWidth
        id="export-type"
        name="name"
        label={$t({ id: 'domain.exportOutputType' })}
        value={outputType}
        onChange={storeEventValue('exportOutputType', setOutputType)}
        sx={{ mb: 2 }}
      >
        {Object.keys(OUTPUT_TYPES).map((type) => (
          <MenuItem key={type} value={type}>
            {$t({ id: `domain.${type}` })}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        control={
          <Checkbox
            checked={mergeLabels}
            onChange={storeEventCheck('exportMergeLabels', setMergeLabels)}
          />
        }
        label={<Typography variant="body2">{$t({ id: 'session.mergeLabels' })}</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showInputData}
            onChange={storeEventCheck('exportShowInputData', setShowInputData)}
          />
        }
        label={<Typography variant="body2">{$t({ id: 'session.inputFormat' })}</Typography>}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showBaseFields}
            onChange={storeEventCheck('exportShowBaseFields', setShowBaseFields)}
          />
        }
        label={<Typography variant="body2">{$t({ id: 'session.showBaseFields' })}</Typography>}
      />
    </Box>
  );

  const exportSessions = () => {
    switch (outputType) {
      case OUTPUT_TYPES.XLSX_TASK_PER_TAB: {
        exportTaskPerTab();
        break;
      }
      case OUTPUT_TYPES.XLSX_TASK_PER_FILE: {
        exportTaskPerFile(true);
        break;
      }
      case OUTPUT_TYPES.CSV_TASK_PER_FILE: {
        exportTaskPerFile(false);
        break;
      }
      default: {
        alert('Unknown output type!');
        break;
      }
    }
  };

  const getDataToExport = (
    inSessionName: string,
    settings: DomainSettings,
    data: GetSessionDataResult
  ) => {
    const flagsColumn: HeaderCellObject = {
      id: STANDARD_FLAGS_COLUMN,
      export: true,
      dataContentGetter: (row: TableRow) =>
        Object.entries(StandardFlags)
          .map(([flag, resultField]) =>
            row[flag] ? settings?.fieldSettings.resultFields[resultField] : null
          ) // There should never be more than one flag on a row.
          .filter(Boolean)[0] ?? null
    };

    const columnsToExport = [flagsColumn, ...data.columns.filter((c) => c.export)];

    const columns = columnsToExport.map((c) => c.label ?? c.id);
    const rows = data.rows.map((row) =>
      columnsToExport.map((col) =>
        col.isDate
          ? parseDateFromTimestamp(row[col.id] as number)
          : col.dataContentGetter?.(row) ?? String(row[col.id])
      )
    );

    let sessionName = inSessionName.replace(excelTabInvalidChars, '-');
    // Excel tab names are limited to 30 characters
    if (sessionName.length > 30) {
      sessionName = `${sessionName.substring(0, 29)}${Constants.ELLIPSIS}`;
    }
    return { sessionName, columns, rows };
  };

  const exportTaskPerFile = (isXlsx: boolean) => {
    if (Array.isArray(selected) && selected.length) {
      let filename = '';
      const extension = isXlsx ? 'xlsx' : 'csv';
      const zip = new JSZip();

      if (settings && sessions) {
        showPreparationBackdrop();
      } else {
        hidePreparationBackdrop();
        return;
      }

      Promise.all(
        selected.map((session, sessionIndex) => {
          if (sessionIndex === 0) {
            filename = session;
          } else if (sessionIndex === selected.length - 1) {
            filename += ` to ${session}`;
          }

          return getSessionData(
            sessions[session],
            settings,
            showInputData,
            showBaseFields,
            mergeLabels
          ).then(async (data) => {
            const { columns, rows, sessionName } = getDataToExport(session, settings, data);

            const { utils, write } = await getXlsx();
            const wb = utils.book_new();
            const ws = utils.aoa_to_sheet([columns, ...rows], {
              dateNF: Constants.TableOutputDateFormat
            });
            utils.book_append_sheet(wb, ws, sessionName);
            const wbOut = write(wb, { bookType: extension, bookSST: true, type: 'binary' }) as Blob;

            // Write file to zip
            zip.file(`${sessionName}.${extension}`, wbOut, { binary: true });
          });
        })
      )
        .then(() => zip.generateAsync({ type: 'blob' }))
        .then((content) => saveAs(content, `${filename}.zip`))
        .then(() => hidePreparationBackdrop())
        .catch((err: Error) => console.log(err.message));
    } else {
      console.log('Missing data!');
    }
  };

  const exportTaskPerTab = () => {
    if (Array.isArray(selected) && selected.length) {
      if (settings && sessions) {
        showPreparationBackdrop();
      } else {
        hidePreparationBackdrop();
        return;
      }

      let filename = '';
      getXlsx()
        .then(({ utils, writeFile }) => {
          const wb = utils.book_new();
          return Promise.all(
            selected.map((session, sessionIndex) => {
              if (sessionIndex === 0) {
                filename = session;
              } else if (sessionIndex === selected.length - 1) {
                filename += ` to ${session}`;
              }

              return getSessionData(
                sessions[session],
                settings,
                showInputData,
                showBaseFields,
                mergeLabels
              ).then((data) => {
                const { columns, rows, sessionName } = getDataToExport(session, settings, data);

                const ws = utils.aoa_to_sheet([columns, ...rows], {
                  dateNF: Constants.TableOutputDateFormat
                });
                utils.book_append_sheet(wb, ws, sessionName);
              });
            })
          )
            .then(() => writeFile(wb, `${filename}.xlsx`, {}) as void)
            .then(() => hidePreparationBackdrop());
        })
        .catch((err: Error) => console.log(err.message));
    } else {
      console.log('Missing data!');
    }
  };

  const handlePageChange = useCallback((page: number) => setPageState({ page }), [setPageState]);
  const handlePageSizeChange = useCallback(
    (pageSize: number) => setPageState({ pageSize }),
    [setPageState]
  );

  const paginatedData = usePaginatedData(
    dataQuery,
    pageState.page,
    pageState.pageSize,
    orderBy,
    order,
    handlePageChange,
    true,
    [],
    true
  );

  const reviewPendingIcon = <ErrorOutlineIcon key="reviewPendingIcon" />;
  const reviewCompletedIcon = (
    <CheckCircleIcon key="reviewCompletedIcon" sx={{ color: green[500] }} />
  );
  const reviewNotAvailableIcon = (
    <CancelIcon key="reviewNotAvailableIcon" sx={{ color: red[500] }} />
  );

  const checkFields: string[] = [];

  // Checking if Domain has checkfields
  settings?.processFlow.labelDesigns
    ? Object.values(settings?.processFlow.labelDesigns).map((ld) => {
        const dataFields = ld.barcodePatterns[0].dataFields;
        dataFields.map((df) => {
          if (df.useCheckField) {
            if (!checkFields.includes(df.checkField)) {
              checkFields.push(df.checkField);
            }
          }
        });
      })
    : null;

  // Checking if Domains has Manual Input Fields
  settings?.processFlow.manualInputFields
    ? Object.values(settings?.processFlow.manualInputFields).map((mif) => {
        if (mif.useCheckField) {
          if (!checkFields.includes(mif.checkField)) {
            checkFields.push(mif.checkField);
          }
        }
      })
    : null;

  const accuracyReportButton = <AssignmentIcon sx={{ color: 'black' }} />;

  useEffect(() => {
    if (!companyData || !paginatedData.data) return;
    const usePushStatus = !!companyData.hasSessionPushListener;

    const headerFieldsTranslation = {};
    headerFields.forEach((field) => {
      headerFieldsTranslation[field] = $t({ id: `domain.${field}` });
    });

    let columns = getHeaderCellsFromObject(headerFieldsTranslation);

    if (usePushStatus && canTriggerSessionPush) {
      const pushStatusColumn: HeaderCellObject = {
        id: 'pushStatus',
        tooltip: 'Push Status',
        icon: {
          iconElement: CloudSyncIcon,
          props: { fontSize: 'large' }
        },
        dataContentGetter: (row) => {
          return row.pushStatus ? (
            <Tooltip
              title={$t({
                id: `domain.ps${row.pushStatus}`,
                defaultMessage: row.pushStatus as string
              })}
            >
              <IconButton
                aria-label="Push status"
                className={row.pushStatus !== 'FAILED' ? classes.disableRipple : undefined}
                onClick={() => onTriggerSessionPushClick(row)}
                style={{
                  color:
                    row.pushStatus === 'FAILED'
                      ? theme.palette.error.main
                      : row.pushStatus === 'PENDING'
                        ? theme.palette.info.main
                        : row.pushStatus === 'PUSHED'
                          ? theme.palette.success.main
                          : theme.palette.warning.main
                }}
              >
                {row.pushStatus === 'FAILED' ? (
                  <SyncProblemIcon />
                ) : row.pushStatus === 'PENDING' ? (
                  <SyncIcon />
                ) : row.pushStatus === 'PUSHED' ? (
                  <CheckCircleIcon />
                ) : (
                  <HelpOutlineIcon />
                )}
              </IconButton>
            </Tooltip>
          ) : null;
        }
      };
      // Insert column on index 1;
      columns.splice(1, 0, pushStatusColumn);
    }
    console.log(columns);

    const sessionDatalist: { [id: string]: Session<string> } = {};
    paginatedData.data.forEach((docSnap) => {
      sessionDatalist[docSnap.id] = docSnap.data();
    });

    let rows = Object.entries(sessionDatalist).map(([key, session]) => ({
      name: key,
      startTime: session.startTime,
      total: session.total || 0,
      empty: session.empty || 0,
      invalid: session.invalid || 0,
      endTime: session.endTime,
      pushStatus: usePushStatus ? session.pushStatus ?? '' : '',
      taskReport: session.wasTask && checkFields.length ? accuracyReportButton : '',
      sessionReview:
        session.reviewState === ReviewState.PENDING
          ? reviewPendingIcon
          : session.reviewState === ReviewState.COMPLETED
            ? reviewCompletedIcon
            : reviewNotAvailableIcon
    }));

    setPageState({
      rowsAndColumns: { rows, columns },
      total: paginatedData.count
    });
  }, [paginatedData.data, paginatedData.count, pageState.page, pageState.pageSize, companyData]);

  return (
    <>
      <EnhancedTable
        tableId="domain"
        selectionColumn={headerFields[0]}
        data={pageState.rowsAndColumns}
        dataLoaded={!!dataQuery && !paginatedData.isLoading}
        clickAction={(row, column) =>
          column.id === 'name'
            ? {
                isLink: true,
                linkPath: `/domains/${domain}/sessions/${row['name'] as string}`
              }
            : column.id === 'sessionReview'
              ? row.sessionReview['key'] !== 'reviewPendingIcon'
                ? undefined
                : {
                    isLink: true,
                    linkPath: `/domains/${domain}/sessions/${row['name'] as string}/review`
                  }
              : column.id === 'taskReport'
                ? {
                    isLink: true,
                    linkPath: `${domain}/sessions/${row['name'] as string}/accuracyreport`
                  }
                : undefined
        }
        title={$t({ id: 'domain.title' }, { domain: domain })}
        toolbarOptions={{
          exportButton: 'selected',
          customExport: (isSelected) => {
            setSelected(isSelected);
            setShowDialog(true);
          },
          deleteButton: {
            title: $t({ id: 'domain.removeSelectedSessions' }),
            onClick: onRemoveSessionsClick
          },
          leftAlignedButtons
        }}
        missingDataAlternative={missingDataAlternative}
        orderData={{ orderBy, order }}
        onOrderChange={onOrderChange}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        totalCount={pageState.total}
      />

      {renderDialog()}
    </>
  );
};

export default Domain;
