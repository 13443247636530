import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { isValidId } from 'flyid-core/dist/Util/database';
import { deburr, isObject } from 'lodash';
import { FormEvent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { groupByCompany } from 'src/components/utils/GroupBy';
import LoadingButton from 'src/components/widgets/LoadingButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useGlobalState from 'src/hooks/useGlobalState';
import useStateReducer from 'src/hooks/useStateReducer';
import { addDomain } from 'src/redux/actions/managementActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectCompanyExhibitionName } from 'src/redux/selectors/dataSelectors';
import {
  selectAuthDomains,
  selectCurrentUserProfile,
  selectTargetCompany,
  selectUserProfiles
} from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { filterModerators } from 'src/util/helpers/user';

const useStyles = appMakeStyles((theme) => ({
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0,
    maxWidth: '750px'
  },
  title: {
    color: theme.other.grey.dark,
    marginBottom: theme.spacing(2)
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  inputContainer: {
    marginBottom: theme.spacing(4)
  },
  margin: {
    marginBottom: theme.spacing(2)
  }
}));

type DomainData = {
  domain: string;
};

const initialDomainData: DomainData = {
  domain: ''
};

const AddDomain: React.FC = () => {
  const [domainData, setDomainData] = useStateReducer<DomainData>(initialDomainData);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();
  const theme = useAppTheme();

  const [copyDomainSettings, setCopyDomainSettings] = useState(false);
  const [domainSettingsToUse, setDomainSettingsToUse] = useState('');

  /* Key user configuration */
  const [targetParentUid, setTargetParentUid] = useGlobalState('targetParentUid');

  const { ui, profile, userProfiles, targetCompany, companyName, parentAuthDomains, authDomains } =
    useAppSelector((s) => {
      const targetCompany = selectTargetCompany(s);
      return {
        ui: s.ui,
        targetCompany,
        companyName: selectCompanyExhibitionName(targetCompany, s),
        profile: selectCurrentUserProfile(s),
        userProfiles: selectUserProfiles(s),
        authDomains: selectAuthDomains(s),
        parentAuthDomains: selectAuthDomains(s, { targetUser: targetParentUid })
      };
    });

  const setCopyDomainSettingsWithEffects = (useCopy: boolean) => {
    if (!useCopy) setDomainSettingsToUse('');
    setCopyDomainSettings(useCopy);
  };

  const hasDomainsCreated = !!authDomains?.length;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const error = isValidId(domainData.domain);
    if (isObject(error)) {
      dispatch(
        updateUi({
          snackbar: {
            message: error,
            severity: 'error',
            show: true
          }
        })
      );

      return;
    }

    if (!profile?.keyUser && !profile?.company) {
      dispatch(
        updateUi({
          snackbar: {
            message: $t({ id: 'Invalid company' }),
            severity: 'error',
            show: true
          }
        })
      );
      return;
    }

    dispatch(
      addDomain({
        domain: domainData.domain,
        currentDomains: parentAuthDomains ?? [],
        copyDomain: domainSettingsToUse
      })
    );
  };

  const moderators = useMemo(() => {
    if (userProfiles && targetCompany) {
      const companyProfiles = groupByCompany(userProfiles)[targetCompany];
      return filterModerators(companyProfiles) ?? [];
    }
    return [];
  }, [targetCompany, userProfiles]);

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={classes.title}>
          {profile?.keyUser
            ? $t({ id: 'addDomain.kuTitle' }, { companyName })
            : $t({ id: 'addDomain.title' })}
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.inputContainer}
        >
          {profile?.keyUser && (
            <Grid item xs={12}>
              <FormControl required fullWidth className={classes.margin}>
                <InputLabel id="moderator-label">{$t({ id: 'moderator' })}</InputLabel>
                <Select
                  disabled={!targetCompany}
                  labelId="moderator-label"
                  id="moderator"
                  name="moderator"
                  value={targetParentUid}
                  onChange={(e) => setTargetParentUid(e.target.value)}
                  input={<OutlinedInput label={$t({ id: 'moderator' })} />}
                  MenuProps={theme.select.getMenuProps()}
                >
                  {moderators.map((moderator) => {
                    return (
                      <MenuItem key={Object.keys(moderator)[0]} value={Object.keys(moderator)[0]}>
                        <ListItemText
                          primary={`${Object.values(moderator)[0].firstName} ${Object.values(moderator)[0].lastName}`}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              id="domain"
              name="domain"
              type="text"
              label={$t({ id: 'addDomain.input' })}
              value={domainData.domain}
              onChange={(e) => setDomainData({ domain: deburr(e.target.value) })}
              className={classes.input}
              autoFocus
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={copyDomainSettings}
                  onChange={(e) => setCopyDomainSettingsWithEffects(e.target.checked)}
                  disabled={profile?.keyUser ? false : !hasDomainsCreated}
                />
              }
              label={
                <Typography variant="body2">
                  {$t({ id: 'addDomain.copyDomainSettings' })}
                </Typography>
              }
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            {hasDomainsCreated && copyDomainSettings ? (
              <FormControl fullWidth>
                <InputLabel id="domain">{$t({ id: 'domain' })}</InputLabel>
                <Select
                  label={$t({ id: 'domain' })}
                  id="domain"
                  value={domainSettingsToUse}
                  autoWidth
                  onChange={(e) => setDomainSettingsToUse(e.target.value)}
                  MenuProps={theme.select.getMenuProps()}
                >
                  {[...authDomains].sort().map((_domain) => (
                    <MenuItem key={_domain} value={_domain}>
                      {_domain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid>
        </Grid>

        <LoadingButton
          isLoading={ui.loadingButton.isAddDomainLoading}
          content={$t({ id: 'addDomain.title' })}
          type="submit"
        />
      </form>
    </Container>
  );
};

export default AddDomain;
