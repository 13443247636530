import { immerable } from 'immer';
import { OnConnectStartParams } from 'reactflow';

// Connection
export class ConnectionData {
  // To allow usage of class instance in redux
  [immerable]? = true;

  isConnecting = false;
  connectionParams?: OnConnectStartParams = undefined;
}

// State
export class ProcessFlowState extends ConnectionData {
  // To allow usage of class instance in redux
  [immerable]? = true;
}
