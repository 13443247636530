import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import { useAppTheme } from 'src/theme/theme';
import { logoutAction, resendEmailVerification } from '../../redux/actions/userActions';
import { isKeyUserProf, isModeratorProf } from '../../util/helpers/user';
import BadRequest from '../widgets/BadRequest';
import LoadingCircle from '../widgets/LoadingCircle';

type ProtectedRouteProps = RouteProps & {
  modOnly?: boolean;
};

enum AuthState {
  NOT_SIGNED,
  LOADING,
  FULLY_SIGNED
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, render, ...rest }) => {
  if (!!component ? !!render : !render) throw Error('Either component OR render must be defined!');

  const { location, modOnly } = rest;

  const { user: authUser, loading: authLoading, usingSSO } = useAuth();
  const { userData, locale } = useAppSelector(({ user, locale }) => ({ userData: user, locale }));
  const { profile, emailVerified, isLoaded: isProfileLoaded, profileError } = userData;

  const authState = useMemo(
    () =>
      // Auth or profile is loading
      authLoading || (authUser && !isProfileLoaded)
        ? AuthState.LOADING
        : !authUser
          ? // Auth is loaded but no user hasn signed in
            AuthState.NOT_SIGNED
          : // User is signed in and profile is loaded
            AuthState.FULLY_SIGNED,
    [authLoading, isProfileLoaded, authUser]
  );

  const hasProfileError = !!profileError;
  const usesPin = usingSSO && (profile.pilot || profile.checker);

  const { $t } = useIntl();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const pathname = location?.pathname;
  const Component = component;

  const thisRender = (props: RouteComponentProps) => {
    // When signing with auth provider, show redirection feedback
    if (usingSSO) {
      if (authState === AuthState.NOT_SIGNED)
        return <LoadingCircle text={$t({ id: 'sso.redirecting' })} />;
      else if (authState !== AuthState.LOADING && hasProfileError) {
        setTimeout(() => dispatch(logoutAction()), 3000);
        return <BadRequest text={$t({ id: 'sso.noProfile' })} />;
      }
    }
    // Loading authentication or profile data
    if (authState === AuthState.LOADING) return <LoadingCircle />;
    else if (authState === AuthState.FULLY_SIGNED) {
      // Show email verification widget if not verified yet
      if (!emailVerified) {
        return (
          <BadRequest
            text={$t({ id: 'lacksEmailVerification' })}
            iconStyle={{ color: theme.palette.warning.main }}
            link={{
              text: $t({ id: 'resendEmailVerification' }),
              actionCallback: () => dispatch(resendEmailVerification(locale.locale))
            }}
          />
        );
      }

      // Inform of network blockage when lacking profile data
      if (profile?.hasOwnProperty('pwUpdated') === false) {
        return (
          <BadRequest
            text={$t({ id: 'networkIsBlockingDomains' })}
            iconStyle={{ color: theme.palette.warning.main }}
          />
        );
      }

      if (profile?.pwUpdated === false) {
        if (!usingSSO && pathname !== '/setpw') {
          return <Redirect to="/setpw" />;
        } else if (pathname !== '/setpin' && usesPin) {
          return <Redirect to="/setpin" />;
        }
      }

      if (modOnly && !isModeratorProf(profile) && !isKeyUserProf(profile)) {
        return <Redirect to="/" />;
      }

      return !!Component ? <Component {...props} /> : render!(props);
    }
    return <Redirect to="/login" />;
  };

  return <Route {...rest} render={thisRender} />;
};

export default ProtectedRoute;
