import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AcquisitionDataState,
  SetDomainSessionsPayload,
  SetDomainTasksPayload
} from '../types/acquisitionTypes';

const acquisitionDataSlice = createSlice({
  name: 'acquisitionData',
  initialState: new AcquisitionDataState(),
  reducers: {
    setDomainTasks: (state, action: PayloadAction<SetDomainTasksPayload>) => {
      const domain = action.payload.domain;
      if (!state[domain]) state[domain] = { sessions: {}, tasks: {} };
      state[domain].tasks = action.payload.tasks;
    },
    setDomainSessions: (state, action: PayloadAction<SetDomainSessionsPayload>) => {
      const domain = action.payload.domain;
      if (!state[domain]) state[domain] = { sessions: {}, tasks: {} };
      state[domain].sessions = action.payload.sessions;
    }
  }
});

export const { setDomainTasks, setDomainSessions } = acquisitionDataSlice.actions;

export default acquisitionDataSlice.reducer;
