"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLabelDesignComplete = exports.LabelDesignRequiredKeys = exports.LabelDesignEntries = exports.LabelDesignKeys = exports.LabelDesign = void 0;
const BarcodePattern_1 = require("./BarcodePattern");
const Vertex_1 = require("./Vertex");
/** Describes data contained in a label for capturing */
class LabelDesign {
    constructor() {
        /** Only used to flag that it uses backup labels */
        this.multiplePerPosition = false;
        this.mayRepeatInSession = false;
        this.firstVertex = new Vertex_1.Vertex();
        this.secondVertex = new Vertex_1.Vertex();
        this.name = '';
        this.barcodePatterns = [];
    }
}
exports.LabelDesign = LabelDesign;
exports.LabelDesignKeys = Object.keys(new LabelDesign());
exports.LabelDesignEntries = Object.entries(new LabelDesign());
exports.LabelDesignRequiredKeys = ['name', 'multiplePerPosition'];
/** Verifies if label data contains the minimum elements for correct functioning */
const isLabelDesignComplete = (ld) => {
    var _a;
    const res = Boolean(ld &&
        ld.firstVertex &&
        ld.secondVertex &&
        ld.name &&
        ((_a = ld.barcodePatterns) === null || _a === void 0 ? void 0 : _a.every((bp) => (0, BarcodePattern_1.isBarcodePatternComplete)(bp))));
    // if (!res)
    //   console.log(
    //     ld?.firstVertex,
    //     ld?.secondVertex,
    //     ld?.name,
    //     ld?.barcodePatterns?.every((bp) => isBarcodePatternComplete(bp)),
    //     ld
    //   );
    return res;
};
exports.isLabelDesignComplete = isLabelDesignComplete;
