import { Session } from 'flyid-core/dist/Database/Models/Session';
import { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import { convertMapToList, MapOf } from 'flyid-core/dist/Util/types';
import { cloneDeep, isFunction, pickBy } from 'lodash';
import { useMemo } from 'react';
import { Nilable } from 'tsdef';
import { AppState } from '../store';
import { MaybeLoaded } from './types';

export const selectSettings = (state: AppState, domain: string) =>
  state.firestore.domainSettings?.[domain];

export const selectSettingsForProcessFlow = (state: AppState, domain: string) => {
  const originalSett = cloneDeep(state.firestore.domainSettings?.[domain]);

  // Convert database maps representing nested lists back to nested lists
  return useMemo(() => {
    if (originalSett) {
      const conditionals = {} as MapOf<CaseData[]>;
      Object.entries(originalSett.processFlow.conditionals as MapOf<MapOf<CaseData>>).forEach(
        ([id, map]) => (conditionals[id] = convertMapToList(map))
      );
      return {
        ...originalSett,
        processFlow: {
          ...originalSett.processFlow,
          conditionals
        }
      };
    }
    return originalSett;
  }, [originalSett]);
};

export const selectSettingsWithMock = (
  state: AppState,
  domain: string,
  mock:
    | Partial<DomainSettings>
    | ((sett: MaybeLoaded<DomainSettings>) => MaybeLoaded<DomainSettings>)
): MaybeLoaded<DomainSettings> => {
  const sett = cloneDeep(state.firestore.domainSettings?.[domain]);

  return useMemo(() => {
    const settRet = isFunction(mock) ? mock(sett) : sett && Object.assign(sett, mock);
    // console.log(sett, settRet);
    return settRet;
  }, [sett]);
};

export const selectSessions = (state: AppState, domain: string) =>
  state.acquisitionData[domain]?.sessions as MaybeLoaded<{ [session: string]: Nilable<Session> }>;

export const selectSession = (state: AppState, domain: string, session: string) =>
  selectSessions(state, domain)?.[session];

export const selectLicenses = (state: AppState, domain?: string) => {
  let licenses = state.firestore.authLicenses;
  return useMemo(() => {
    if (domain) {
      licenses = pickBy(licenses, (lic) => lic.authDomains.includes(domain));
    }
    return licenses;
  }, [licenses]);
};

export const selectApiKeys = (state: AppState) => state.firestore.apiKeys;

export const labelImageSelector = (domain: Nilable<string>, labelId: string, state: AppState) =>
  domainLabelImagesSelector(domain, state)?.[labelId] || undefined;

export const domainLabelImagesSelector = (domain: Nilable<string>, state: AppState) =>
  (domain && state.labelImages[domain]) || undefined;

export const selectCompaniesData = (state: AppState) => state.firestore.companiesData;

export const selectCompanyExhibitionName = (company: string | undefined, state: AppState) =>
  company ? selectCompaniesData(state)?.[company]?.exhibitionName ?? company : company;
