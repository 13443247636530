import {
  Button,
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Typography
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionsHandler';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectLicenses } from 'src/redux/selectors/dataSelectors';
import { MyDialogState } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { buildLicenseDescription } from 'src/util/helpers/other';
import { selectDomainParent } from 'src/redux/selectors/userSelectors';

const useStyles = appMakeStyles(({ resizableContainer, spacing, other, form }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: form.maxWidth
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  formControl: {
    width: '100%'
  }
}));

export default function LicensesEditor() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { select } = useAppTheme();
  const intl = useIntl();
  const { $t } = intl;

  const { domain } = useParams<DomainMatchParams>();

  const [licenseKeys, setLicenseKeys] = useState<string[]>([]);

  const { licenses, parentUid } = useAppSelector((s) => ({
    parentUid: selectDomainParent(s, domain),
    licenses: selectLicenses(s)
  }));

  useEffect(() => {
    if (licenses) {
      setLicenseKeys(
        Object.entries(licenses)
          .filter(([, l]) => l.authDomains.includes(domain))
          .map(([k]) => k)
      );
    }
  }, [licenses]);

  const showConfirmationDialog = (event: FormEvent) => {
    event.preventDefault();
    if (!licenses) return;

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: `editLic.licEditConfTitle` }),
          message: $t({ id: `editLic.licEditConfMsg` }),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: `editLic.licEditConfCheck` })}</b>,
          show: true
        }).setConfirmAction(Actions.EDIT_DOMAIN_LICENSES, {
          parentUid,
          data: { domain, licenseKeys }
        })
      })
    );
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={showConfirmationDialog}>
        <Typography variant="h4" className={classes.title}>
          {$t({ id: 'editLic.title' }, { domain })}
        </Typography>

        {licenses ? (
          !isEmpty(licenses) ? (
            <>
              <FormControl className={classes.formControl}>
                <InputLabel id="auth-licenses-select-label">
                  {$t({ id: 'editLic.license' })}
                </InputLabel>
                <Select
                  labelId="auth-licenses-select-label"
                  id="auth-licenses-select"
                  multiple
                  value={licenseKeys}
                  onChange={(e) => setLicenseKeys(e.target.value as string[])}
                  input={<OutlinedInput label={$t({ id: 'editLic.license' })} />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={select.getMenuProps()}
                >
                  {Object.entries(licenses).map(([key, lic]) => (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={licenseKeys.indexOf(key) > -1} />
                      {buildLicenseDescription(lic, key, intl)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button sx={{ mt: 2 }} type="submit" color="secondary" variant="contained">
                {$t({ id: 'saveChanges' })}
              </Button>
            </>
          ) : (
            <Typography>{$t({ id: 'editLic.noUnused' })}</Typography>
          )
        ) : (
          <Skeleton variant="rectangular" animation="wave" height={30} />
        )}
      </form>
    </Container>
  );
}
