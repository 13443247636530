import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { immerable } from 'immer';
import { DeepPartial, Nilable } from 'tsdef';

export class LabelState {
  [immerable]? = true;
  /** The image src from cloud storage */
  src?: string;
  /** The image src defined by the user locally (the user selected an image) */
  localSrc?: string;
  /** Whether fetching has started or not */
  isTriggered? = false;
  /** Whether fetching has finished or not (may have failed) */
  isLoaded? = false;
}

export type UpdateLabelImageStateActionData = {
  labelId: string;
  domain: string;
  labelState: DeepPartial<LabelState>;
};

export type SetLabelImageLoadedActionData = {
  labelId: string;
  domain: string;
  src?: string;
};

export type RemoveLabelImageActionData = {
  labelId: string;
  domain: string;
};

export type ManagementState = { [domain: string]: { [labelId: string]: Nilable<LabelState> } };

const managementSlice = createSlice({
  name: 'locale',
  initialState: {} as ManagementState,
  reducers: {
    setLabelImageFetchTriggered: (state, action: PayloadAction<RemoveLabelImageActionData>) => {
      const labelId = action.payload.labelId;
      const domain = action.payload.domain;
      if (!state[domain]) state[domain] = {};

      state[domain][labelId] = Object.assign(state[domain][labelId] || new LabelState(), {
        isTriggered: true,
        isLoaded: false
      });
    },
    setLabelImageLoaded: (state, action: PayloadAction<SetLabelImageLoadedActionData>) => {
      const { labelId, domain, src } = action.payload;

      if (!state[domain]) state[domain] = {};

      state[domain][labelId] = Object.assign(state[domain][labelId] || new LabelState(), {
        src: src,
        isTriggered: false,
        isLoaded: true
      });
    },
    updateLabelImageState: (state, action: PayloadAction<UpdateLabelImageStateActionData>) => {
      const { labelState, labelId, domain } = action.payload;

      if (!state[domain]) state[domain] = {};

      state[domain][labelId] = Object.assign(
        state[domain][labelId] || new LabelState(),
        labelState
      );
    }
  }
});

export const { setLabelImageFetchTriggered, setLabelImageLoaded, updateLabelImageState } =
  managementSlice.actions;

export default managementSlice.reducer;
