import { Session } from 'flyid-core/dist/Database/Models/Session';
import { Task } from 'flyid-core/dist/Database/Models/Task';
import { immerable } from 'immer';

export type SetDomainSessionsPayload = {
  domain: string;
  sessions: { [sessionName: string]: Session };
};

export type SetDomainTasksPayload = {
  domain: string;
  tasks: { [taskName: string]: Task };
};

export class AcquisitionDataState {
  [immerable]? = true;
  [domain: string]: {
    sessions: { [sessionName: string]: Session };
    tasks: { [taskName: string]: Task };
  };
}
