import { cx } from '@emotion/css';
import { Clear, SaveAlt, Search } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  lighten,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';
import { parseDateFromTimestamp } from 'flyid-core/dist/Util/time';
import { Constants } from 'flyid-core/dist/Common';
import React, { CSSProperties, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { TableColumns, TableRows } from 'src/util/helpers/table';
import { Selector } from './EnhancedTable';
import { getXlsx } from 'src/util/lazyLoaders';

const useStyles = appMakeStyles(({ spacing, palette }) => ({
  root: {
    paddingLeft: spacing(0),
    paddingRight: spacing(0),
    marginBottom: spacing(1),
    borderRadius: 10
  },
  highlight: {
    ...(palette.mode === 'light'
      ? {
          color: palette.secondary.dark,
          backgroundColor: lighten(palette.secondary.light, 0.7)
        }
      : {
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.dark
        }),
    paddingLeft: spacing(1)
  },
  searchBox: {
    minWidth: spacing(32)
  },
  leftJustifiedFlexContainer: {
    flex: '1 1 100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  rightJustifiedFlexContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexShrink: 0
  },
  button: {
    width: spacing(48),
    marginRight: spacing(4)
  },
  buttonText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  extraSelectorsFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing(2)
  }
}));

export type ExportButtonType = 'show' | 'selected' | 'hidden';

export type EnhancedTableToolbarOptions = {
  exportButton?: ExportButtonType;
  title?: string;
  customExport?: (selected: string[]) => void;
  exportFileName?: string;
  extraSelectors?: Selector[];
  link?: {
    text: string;
    path: string;
    state?: unknown;
  };
  deleteButton?: {
    title: string;
    onClick: (selected: string[]) => (e: MouseEvent<HTMLButtonElement>) => void;
  };
  leftAlignedButtons?: JSX.Element;
  searchFieldAlignment?: 'left' | 'right';
  search?: {
    searchText: string;
    onSearchChange: (searchText: string) => void;
    inputStyle?: CSSProperties;
  };
  disabled?: boolean;
};

export type Props = EnhancedTableToolbarOptions & {
  rows: TableRows;
  columns: TableColumns;
  selected: string[];
};

const EnhancedTableToolbar: React.FC<Props> = (props) => {
  const { columns, rows, selected, title, link } = props;
  const { $t } = useIntl();

  const classes = useStyles();
  const theme = useAppTheme();
  const [exportAnchor, setExportAnchor] = React.useState<Element | null>(null);

  const dataIsEmpty = rows.length && columns.length;
  const isSelection = selected && selected.length > 0;
  const firstKey = columns?.[0]?.id ?? '';

  const filterDataBeforeExport = () => {
    if (!dataIsEmpty) return false;

    const columnsToExport = columns.filter((column) => {
      return !column.hidden && column.id && column.export !== false;
    });

    const dataToExport = isSelection
      ? rows.filter((row) => selected.includes(row[firstKey] as string))
      : rows;

    return {
      filteredColumns: columnsToExport.map((column) => column.label ?? column.id),
      filteredRows: dataToExport.map((row) =>
        columnsToExport.map((column) => {
          return column.isDate
            ? parseDateFromTimestamp(row[column.id] as number)
            : column.dataContentGetter?.(row) ?? String(row[column.id]);
        })
      )
    };
  };

  const exportTo = (format: 'xls' | 'xlsx' | 'csv') => () => {
    const data = filterDataBeforeExport();
    if (!data) {
      alert('Error: No data available!');
      return;
    }

    const { filteredColumns, filteredRows } = data;

    getXlsx()
      .then(({ utils, writeFile }) => {
        console.log(filteredColumns, filteredRows);
        const ws = utils.aoa_to_sheet([filteredColumns, ...filteredRows], {
          dateNF: Constants.TableOutputDateFormat
        });
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'data');

        writeFile(wb, `${props.exportFileName || props.title || 'data'}.${format}`);
        setExportAnchor(null);
      })
      .catch((err) => console.error('Failed exporting!', err));
  };

  const searchAlignedLeft = props.searchFieldAlignment === 'left';

  const renderExtraSelectors = () => {
    return (
      (props.extraSelectors && props.extraSelectors.length && (
        <FormControl component="fieldset">
          <FormGroup className={classes.extraSelectorsFormGroup}>
            {props.extraSelectors.map((selector) => (
              <FormControlLabel
                control={selector.control}
                label={selector.label}
                key={`selector${selector.label}`}
              />
            ))}
          </FormGroup>
        </FormControl>
      )) ||
      null
    );
  };

  const renderDeleteButton = () =>
    props.deleteButton &&
    isSelection && (
      <Tooltip title={props.deleteButton.title}>
        <IconButton
          color="inherit"
          onClick={props.deleteButton.onClick(selected)}
          aria-label={props.deleteButton.title}
          size="large"
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Tooltip>
    );

  const renderSearch = () => {
    const search = props.search;
    return search ? (
      <TextField
        className={classes.searchBox}
        sx={searchAlignedLeft ? { py: 0, px: 2 } : undefined}
        value={search.searchText}
        onChange={(event) => search.onSearchChange(event.target.value)}
        placeholder={$t({ id: 'ett.searchPlaceholder' })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title={$t({ id: 'ett.searchTooltip' })}>
                <Search color="inherit" fontSize="small" />
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!search.searchText}
                onClick={() => search.onSearchChange('')}
                size="large"
              >
                <Clear color="inherit" fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          style: search.inputStyle
        }}
      />
    ) : null;
  };

  const renderExportButton = () => {
    const showOnSelection = props.exportButton === 'selected' && isSelection;
    const alwaysShow = props.exportButton === 'show';
    const shouldRender = alwaysShow || showOnSelection;

    return shouldRender ? (
      <>
        <Tooltip
          title={
            isSelection ? $t({ id: 'ett.exportSelectedTitle' }) : $t({ id: 'ett.exportTitle' })
          }
        >
          <IconButton
            disabled={props.disabled}
            color="inherit"
            onClick={(e) => props.customExport?.(selected) ?? setExportAnchor(e.currentTarget)}
            aria-label={
              isSelection ? $t({ id: 'ett.exportSelectedTitle' }) : $t({ id: 'ett.exportTitle' })
            }
            size="large"
          >
            <SaveAlt />
          </IconButton>
        </Tooltip>
        {!props.customExport && (
          <Menu
            anchorEl={exportAnchor}
            open={Boolean(exportAnchor)}
            onClose={() => setExportAnchor(null)}
          >
            <MenuItem key="export-csv" onClick={exportTo('csv')}>
              {isSelection
                ? $t({ id: 'ett.exportSelectedCsvTitle' })
                : $t({ id: 'ett.exportCsvTitle' })}
            </MenuItem>
            <MenuItem key="export-xlsx" onClick={exportTo('xlsx')}>
              {isSelection
                ? $t({ id: 'ett.exportSelectedXlsxTitle' })
                : $t({ id: 'ett.exportXlsxTitle' })}
            </MenuItem>
          </Menu>
        )}
      </>
    ) : null;
  };

  // const renderFilterButton = () =>
  //   props.exportButton ? (
  //     <>
  //       <Tooltip>
  //         <IconButton
  //           color="inherit"
  //           onClick={(event) => setExportAnchor(event.currentTarget)}
  //           aria-label="Filter list"
  //           size="large"
  //         >
  //           <FilterListIcon />
  //         </IconButton>
  //       </Tooltip>
  //       <Menu
  //         anchorEl={exportAnchor}
  //         open={Boolean(exportAnchor)}
  //         onClose={() => setExportAnchor(null)}
  //       >
  //         <MenuItem key="export-csv" onClick={exportTo('csv')}>
  //           {$t({ id: 'ett.exportName' })}
  //         </MenuItem>
  //       </Menu>
  //     </>
  //   ) : null;

  const renderHead = () =>
    // Priority order: selection feedback -> leftAlignedButtons -> title
    selected.length > 0 ? (
      <Typography color="inherit" variant="subtitle1">
        {$t({ id: `ett.selectedText` }, { number: selected.length })}
      </Typography>
    ) : props.leftAlignedButtons ? (
      <Box sx={{ mr: 2 }}>{props.leftAlignedButtons}</Box>
    ) : (
      title && (
        <Typography sx={theme.text.tableTitle} variant="h5" id="tableTitle">
          {title}
        </Typography>
      )
    );

  return (
    <Toolbar
      className={cx(classes.root, {
        [classes.highlight]: selected.length > 0
      })}
    >
      <div className={classes.leftJustifiedFlexContainer}>
        {renderHead()}
        {searchAlignedLeft && (
          <>
            {renderSearch()}
            {/* {renderFilterButton()} */}
            {renderExtraSelectors()}
          </>
        )}
      </div>
      <div className={classes.rightJustifiedFlexContainer}>
        {link && (
          <Button
            component={Link}
            to={{ pathname: link.path, state: link.state }}
            className={classes.button}
          >
            <Box className={classes.buttonText}>{link.text}</Box>
          </Button>
        )}

        {!searchAlignedLeft && (
          <>
            {renderExtraSelectors()}
            {/* {renderFilterButton()} */}
            {renderSearch()}
          </>
        )}
        {renderDeleteButton()}
        {renderExportButton()}
      </div>
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
