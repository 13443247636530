"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypedFlags = exports.StandardFlags = exports.AcquisitionDataFlags = exports.INTERNAL_FLAG_PREFIX = exports.AcquisitionDataFlagsV1 = exports.AddressMarkers = void 0;
exports.AddressMarkers = ['*', 'R', 'C', 'N', 'A'];
/**
 * @deprecated These flags should only be used for migration purposes
 */
var AcquisitionDataFlagsV1;
(function (AcquisitionDataFlagsV1) {
    AcquisitionDataFlagsV1["HasPicture"] = "__hasPicture";
    AcquisitionDataFlagsV1["ScannedCodes"] = "__scannedCode";
    AcquisitionDataFlagsV1["PictureFilename"] = "__pictureFilename";
})(AcquisitionDataFlagsV1 = exports.AcquisitionDataFlagsV1 || (exports.AcquisitionDataFlagsV1 = {}));
exports.INTERNAL_FLAG_PREFIX = 'internal_flag_';
var AcquisitionDataFlags;
(function (AcquisitionDataFlags) {
    AcquisitionDataFlags["HasPictures"] = "internal_flag_hasPictures";
    AcquisitionDataFlags["ScannedCodes"] = "internal_flag_scannedCode";
    AcquisitionDataFlags["PictureFilenames"] = "internal_flag_pictureFilenames";
    AcquisitionDataFlags["DataSource"] = "internal_flag_dataSource";
    AcquisitionDataFlags["DataHash"] = "internal_flag_dataHash";
    AcquisitionDataFlags["DataHasBeenMerged"] = "internal_flag_dataHasBeenMerged";
})(AcquisitionDataFlags = exports.AcquisitionDataFlags || (exports.AcquisitionDataFlags = {}));
/** Maps standard flags to result field names */
var StandardFlags;
(function (StandardFlags) {
    StandardFlags["isEmpty"] = "acquisition_flag_empty";
    StandardFlags["isInvalid"] = "acquisition_flag_invalid";
})(StandardFlags = exports.StandardFlags || (exports.StandardFlags = {}));
class TypedFlags {
    constructor() {
        this[_a] = false;
        this[_b] = [];
        this[_c] = '';
    }
}
exports.TypedFlags = TypedFlags;
StandardFlags.isInvalid, StandardFlags.isEmpty, _a = AcquisitionDataFlags.HasPictures, _b = AcquisitionDataFlags.ScannedCodes, _c = AcquisitionDataFlags.PictureFilenames;
