import { Company } from 'flyid-core/dist/Database/Models';
import type { APIKey } from 'flyid-core/dist/Database/Models/APIKey';
import type { AuthenticationProvider } from 'flyid-core/dist/Database/Models/CompanySettings';
import type { License } from 'flyid-core/dist/Database/Models/License';
import type { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import type { UserPublic } from 'flyid-core/dist/Database/Models/User';
import { immerable } from 'immer';
import type { Nilable, Undefinable } from 'tsdef';

export type SetAuthProviderData = Undefinable<{ [company: string]: AuthenticationProvider }>;
export type UpdateDomainSettingsData = Nilable<{ [domain: string]: DomainSettings }>;
export type UpdateUserProfilesData = Nilable<{ [uid: string]: UserPublic }>;
export type UpdateCompaniesData = Nilable<{ [company: string]: Company }>;
export type UpdateAuthDomains = Nilable<{ [company: string]: string[] }>;
export type UpdateAuthLicensesData = Nilable<{ [license: string]: License }>;
export type UpdateAPIKeysData = Nilable<{ [apiKey: string]: APIKey }>;

export class FirestoreState {
  [immerable] = true;

  authProviders: {
    areAuthProvidersLoaded: boolean;
    providers?: { [company: string]: AuthenticationProvider };
  } = { areAuthProvidersLoaded: false };
  domainSettings?: { [domain: string]: DomainSettings };
  userProfiles?: { [uid: string]: UserPublic };
  authLicenses?: { [license: string]: License };
  apiKeys?: { [apiKey: string]: APIKey };
  companiesData?: { [company: string]: Company };
  authDomains?: { [company: string]: string[] };
}
