import axios, { AxiosRequestConfig } from 'axios';
import { Auth } from 'firebase/auth';
import { cloneDeep } from 'lodash';
import environment from 'src/environment';
import {
  selectCurrentUserProfile,
  selectTargetCompany,
  selectTargetParentUid
} from 'src/redux/selectors/userSelectors';
import { AppState } from 'src/redux/store';

axios.defaults.baseURL = environment.serverApi;

const authAxios = async (auth: Auth, state: AppState, config: AxiosRequestConfig) => {
  // Must be logged in to make an authenticated call
  const currUser = auth.currentUser;
  if (!currUser) throw Error('Not logged in!');

  // Handle Key User calls by adding expected data.
  const isKeyUser = !!selectCurrentUserProfile(state)?.keyUser;
  const keyUserHeaders: Record<string, string> = {};

  if (isKeyUser) {
    const targetCompany = selectTargetCompany(state);
    const targetParentUid = selectTargetParentUid(state);
    if (targetCompany) keyUserHeaders.company = targetCompany;
    if (targetParentUid) keyUserHeaders.parentUid = targetParentUid;
  }

  const headers = {
    ...keyUserHeaders,
    ...cloneDeep(config.headers)
  };
  delete config.headers;

  return currUser.getIdToken(true).then((token) =>
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept-Language': state.locale.locale,
        ...headers
      },
      withCredentials: true,
      ...config
    })
  );
};

export type WithPossibleParent<T> = { parentUid?: string; data: T };
export function withParentUid<T>(
  config: AxiosRequestConfig,
  data: WithPossibleParent<T>
): AxiosRequestConfig {
  config.data = data.data;
  if (data.parentUid) {
    config.headers = { ...config.headers, parentUid: data.parentUid };
  } else {
    delete config.headers?.parentUid;
  }
  return config;
}

export default authAxios;
