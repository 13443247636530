import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  Typography
} from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import { useParams } from 'react-router-dom';
import { green } from '@mui/material/colors';
import { useIntl } from 'react-intl';

const useStyles = appMakeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3)
  },
  headerCell: {
    fontWeight: 'bold'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  alteredValue: {
    color: green[500],
    fontSize: 'inherit',
    fontFamily: 'inherit'
  }
}));

type ReviewedData = { checkFields: string[]; originalData: {}[]; changedData: {}[] };

const SessionPreview: React.FC<ReviewedData> = (props) => {
  const { session } = useParams<SessionMatchParams>();
  const classes = useStyles();
  const intl = useIntl();
  const $t = intl.$t;

  return (
    <>
      {props.changedData.length && props.originalData.length ? (
        <>
          <Typography variant="h6" className={classes.title}>
            {`${$t({ id: 'domain.sessionReview' })} - ${session} - ${$t({ id: 'preview' })}`}
          </Typography>

          <TableContainer component={Paper} className={classes.container}>
            <Table sx={{ minWidth: 650 }} aria-label="preview-table">
              <TableHead>
                <TableRow>
                  <TableCell key={'address'} align="right" className={classes.headerCell}>
                    {$t({ id: 'address' })}
                  </TableCell>
                  {props.checkFields.map((field: string) => {
                    return (
                      <TableCell key={field} align="right" className={classes.headerCell}>
                        {field}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.changedData.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="right" key={`address${props.originalData[index]['address']}`}>
                      {props.originalData[index]['address']}
                    </TableCell>

                    {props.originalData[index] &&
                      props.checkFields.map((field, fieldIndex) => (
                        <TableCell key={fieldIndex} align="right">
                          {props.changedData[index][field] !== undefined ? (
                            <Typography className={classes.alteredValue}>
                              {props.changedData[index][field]}
                            </Typography>
                          ) : props.originalData[index][field] === '' ? (
                            $t({ id: 'null' })
                          ) : (
                            props.originalData[index][field]
                          )}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
};

export default SessionPreview;
