import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { storeValue } from 'flyid-core/dist/Util/web';
import { immerable } from 'immer';
import { cloneDeep } from 'lodash';

const GLOBAL_STORAGE_KEY = 'global-storage-key';

export class GlobalsState {
  [immerable] = true;
  /** The globally selected company */
  targetCompany?: string = undefined;
  /** The globally selected parentUid for actions that allow selection of a parent moderador */
  targetParentUid?: string = undefined;
}
export type GlobalStateKey = keyof GlobalsState;

export const getGlobalStorageKey = (key: GlobalStateKey) =>
  `${GLOBAL_STORAGE_KEY}-${key as string}`;

const globalsSlice = createSlice({
  name: 'globals',
  initialState: new GlobalsState(),
  reducers: {
    updateGlobals: (state, action: PayloadAction<Partial<GlobalsState>>) => {
      const withEffects = { ...action.payload };
      // Apply transformation effects depending on data change
      if (action.payload.hasOwnProperty('targetCompany')) {
        // When 'targetCompany' changes but 'targetParentUid' doesn't, we have to reset 'targetParentUid'
        // Since the targeted user does cannot belong to the new target company.
        if (!action.payload.hasOwnProperty('targetParentUid')) {
          withEffects['targetParentUid'] = '';
        }
      }

      Object.assign(state, withEffects);
      Object.entries(state).forEach(([key, value]) => {
        storeValue(getGlobalStorageKey(key as GlobalStateKey), value);
      });
    },
    resetGlobals: (state) => {
      state = cloneDeep(new GlobalsState());
      Object.keys(state).forEach((key, value) => {
        storeValue(getGlobalStorageKey(key as GlobalStateKey), value);
      });
    }
  }
});
export type GlobalsSlice = typeof globalsSlice;

export const { updateGlobals, resetGlobals } = globalsSlice.actions;

export default globalsSlice.reducer;
