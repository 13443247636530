import FlagIcon from '@mui/icons-material/Flag';
import React, { memo } from 'react';
import { NodeProps } from 'reactflow';
import { useIntl } from 'react-intl';
import BaseNode, { Content, Handles } from './BaseNode';
import { CommonNodeData } from 'src/util/processFlow/types';
import { arePropsEqual, isBaseNodeDataEqual, isSpecificDataEqual } from 'src/util/processFlow/node';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';

export const EndNode: React.FC<NodeProps<CommonNodeData>> = (props) => {
  const { $t } = useIntl();

  const content: Content = {
    iconEnd: FlagIcon,
    description: $t({ id: 'processFlow.End' })
  };
  const handles: Handles = {
    inputHandles: [HandleType.END]
  };

  return (
    <div>
      {/* Content and basic handlers */}
      <BaseNode
        id={props.id}
        selected={props.selected}
        content={content}
        handles={handles}
        {...props.data.baseNodeData}
      />
    </div>
  );
};

export default memo(EndNode, arePropsEqual(isSpecificDataEqual, isBaseNodeDataEqual));
