import {
  // AddLabelDesignParams,
  EditDomainFieldsSettingsParams,
  // EditLabelDesignParams,
  EditLicensesParams,
  RemoveApiKeyParams,
  RemoveDomainParams,
  RemoveTableParams,
  RenewApiKeyParams,
  ResetUserPasswordParams
} from './managementActions';
import {
  RemoveSessionsParams,
  RemoveTaskParams,
  RestartTaskParams,
  UpdateSessionParams
} from './operationalActions';
import { EditUserParams, LogoutParams, RemoveUserParams } from './userActions';

export enum Actions {
  NONE = 'NONE',
  // User Actions
  EDIT_USER = 'EDIT_USER',
  REMOVE_USER = 'REMOVE_USER',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  LOGOUT = 'LOGOUT',
  // Domain actions
  REMOVE_SESSIONS = 'REMOVE_SESSIONS',
  REMOVE_DOMAIN = 'REMOVE_DOMAIN',
  // Inventory task actions
  RESTART_INVENTORY_TASK = 'RESTART_INVENTORY_TASK',
  REMOVE_INVENTORY_TASK = 'REMOVE_INVENTORY_TASK',
  //Domain Settings Actions
  EDIT_DOMAIN_SETTINGS_FIELDS = 'EDIT_DOMAIN_SETTINGS_FIELDS',
  // ADD_LABEL_DESIGN = 'ADD_LABEL_DESIGN',
  // EDIT_LABEL_DESIGN = 'EDIT_LABEL_DESIGN',
  // REMOVE_LABEL_DESIGN = 'REMOVE_LABEL_DESIGN',
  // Domain Licenses
  EDIT_DOMAIN_LICENSES = 'EDIT_DOMAIN_LICENSES',
  REMOVE_TABLE = 'REMOVE_TABLE',
  // API Key management
  RENEW_API_KEY = 'RENEW_API_KEY',
  REMOVE_API_KEY = 'REMOVE_API_KEY',
  // Session Review
  UPDATE_SESSION = 'UPDATE_SESSION'
}

export type ParameterMap = {
  NONE: undefined | null;
  // User Actions
  EDIT_USER: EditUserParams;
  REMOVE_USER: RemoveUserParams;
  RESET_USER_PASSWORD: ResetUserPasswordParams;
  LOGOUT: LogoutParams;
  // Domain actions
  REMOVE_SESSIONS: RemoveSessionsParams;
  REMOVE_DOMAIN: RemoveDomainParams;
  // Inventory task actions
  RESTART_INVENTORY_TASK: RestartTaskParams;
  REMOVE_INVENTORY_TASK: RemoveTaskParams;
  // Domain Settings Actions
  EDIT_DOMAIN_SETTINGS_FIELDS: EditDomainFieldsSettingsParams;
  EDIT_DOMAIN_LICENSES: EditLicensesParams;
  REMOVE_TABLE: RemoveTableParams;
  // Add/remove/edit label and Mif will be removed soon
  // ADD_LABEL_DESIGN: AddLabelDesignParams;
  // EDIT_LABEL_DESIGN: EditLabelDesignParams;
  // REMOVE_LABEL_DESIGN: undefined | null;
  REMOVE_MANUAL_INPUT: undefined | null;
  // API Key management
  RENEW_API_KEY: RenewApiKeyParams;
  REMOVE_API_KEY: RemoveApiKeyParams;
  // Session Review
  UPDATE_SESSION: UpdateSessionParams;
};
