import { useMemo, useState } from 'react';
import { getStoredOrDefault } from 'flyid-core/dist/Util/web';
import { appMakeStyles } from 'src/theme/theme';
import { Box, IconButton } from '@mui/material';
import AccuracyReportTable from './AccuracyReportTable';
import DivergencyTable from './DivergencyTable';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectCurrentUserProfile, selectTargetCompany } from 'src/redux/selectors/userSelectors';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { useSessionDataParser } from 'src/workers/dataWorkerApi';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { buildDocumentRef } from 'src/firebase/firestore';
import { getSessionDoc } from 'flyid-core/dist/Util/database';
import { isTranslatableError } from 'src/util/locale';
import { HeaderCellObject, TableRow } from 'src/util/helpers/table';
import { PICTURES_COLUMN, STANDARD_FLAGS_COLUMN } from 'src/util/helpers/table';
import { AcquisitionDataFlags } from 'flyid-core/dist/Database/Models';
import FlagIcon from '@mui/icons-material/Flag';
import SearchIcon from '@mui/icons-material/Search';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { StandardFlags } from 'flyid-core/dist/Database/Models';
import LoadingCircle from '../widgets/LoadingCircle';

type Props = any;
type BarcodeFieldsToCheck = { field: string; checkField: string; isRequired: boolean }[];

const showInputDataStorageKey = 'sessionShowInputData';
const showBaseFieldsStorageKey = 'sessionShowBaseFields';
const mergeLabelsStorageKey = 'sessionMergeLabels';

const useStyles = appMakeStyles((theme) => ({
  container: { ...theme.resizableContainer(2), marginLeft: 0 },
  tableContainer: {
    marginBottom: '20px'
  }
}));

const AccuracyReport: React.FC<Props> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const $t = intl.$t;

  const { match } = props;
  const domain: string = match.params.domain;
  const session: string = match.params.session;

  const { profile, settings, locale, company } = useAppSelector((s) => ({
    company: selectTargetCompany(s),
    profile: selectCurrentUserProfile(s),
    settings: selectSettings(s, domain),
    locale: s.locale.locale
  }));

  const [sessionData, loadingSessionData] = useDocumentDataOnce(
    profile && company ? buildDocumentRef(getSessionDoc(company, domain, session)) : undefined
  );

  let wasTask = (sessionData && sessionData.wasTask) || false;

  const [showInputData, setShowInputData] = useState(
    wasTask && getStoredOrDefault(showInputDataStorageKey, false)
  );

  const [showBaseFields, setShowBaseFields] = useState(
    getStoredOrDefault(showBaseFieldsStorageKey, false)
  );
  const [mergeLabels, setMergeLabels] = useState(getStoredOrDefault(mergeLabelsStorageKey, false));

  const { isWorking, rowsAndColumnsOrError: parsedDataOrError } = useSessionDataParser(
    sessionData,
    settings,
    showInputData,
    showBaseFields,
    mergeLabels
  );

  const rowsAndColumnsOrError = useMemo(() => {
    if (isTranslatableError(parsedDataOrError) || !parsedDataOrError)
      return { columns: [], rows: [] };

    const picturesColumn: HeaderCellObject = {
      id: PICTURES_COLUMN,
      tooltip: $t({ id: 'session.associatedPictures' }),
      export: false,
      icon: {
        iconElement: WallpaperIcon,
        props: {
          fontSize: 'large'
        }
      },
      headerCellProps: {
        padding: 'none',
        align: 'center'
      },
      dataCellProps: {
        align: 'center'
      },
      dataContentGetter: (row: TableRow) =>
        !!row[AcquisitionDataFlags.HasPictures] ? (
          <IconButton aria-label="viewImage" size="large">
            <SearchIcon fontSize="medium" />
          </IconButton>
        ) : null
    };
    const flagsColumn: HeaderCellObject = {
      id: STANDARD_FLAGS_COLUMN,
      export: true,
      tooltip: $t({ id: 'session.acquisitionFlags' }),
      icon: {
        iconElement: FlagIcon,
        props: {
          fontSize: 'large'
        }
      },
      headerCellProps: {
        padding: 'none',
        align: 'center'
      },
      dataCellProps: {
        align: 'center'
      },
      dataContentGetter: (row: TableRow) =>
        Object.entries(StandardFlags)
          .map(([flag, resultField]) =>
            row[flag] ? settings?.fieldSettings.resultFields[resultField] : null
          ) // There should never be more than one standard flag on a row.
          .filter(Boolean)[0] ?? null
    };

    return {
      columns: [picturesColumn, flagsColumn, ...parsedDataOrError.columns],
      rows: parsedDataOrError.rows
    };
  }, [parsedDataOrError, locale]);

  const barcodeFieldsToCheck: BarcodeFieldsToCheck = [];

  // Getting barcode fields that need to be check
  settings?.processFlow.labelDesigns
    ? Object.values(settings?.processFlow.labelDesigns).forEach((ld) => {
        const dataFields = ld.barcodePatterns[0].dataFields;
        dataFields
          .filter((df) => Boolean(df.useCheckField))
          .forEach((df) => {
            barcodeFieldsToCheck.push({
              field: df.checkField,
              checkField: df.name,
              isRequired: ld.barcodePatterns[0].isRequired
            });
          });
      })
    : null;

  // Getting manual input fields that need to be check
  settings?.processFlow.manualInputFields
    ? Object.values(settings?.processFlow.manualInputFields)
        .filter((mifs) => Boolean(mifs.useCheckField))
        .forEach((mif) => {
          barcodeFieldsToCheck.push({
            field: mif.checkField,
            checkField: mif.field,
            isRequired: mif.mandatory
          });
        })
    : null;

  // Filtering barcode fileds to check
  const filteredData = useMemo(() => {
    let filteredArray: BarcodeFieldsToCheck = [];
    let checkArray: string[] = [];

    //eslint-disable-next-line
    barcodeFieldsToCheck?.map((bfc) => {
      if (checkArray.length && filteredArray.length) {
        if (!checkArray.some((v) => v === bfc.field)) {
          checkArray.push(bfc.field);
          filteredArray.push(bfc);
        } else {
          if (bfc.isRequired === true) {
            filteredArray.splice(filteredArray.length - 1, 1, bfc);
          }
        }
      } else {
        checkArray.push(bfc.field);
        filteredArray.push(bfc);
      }
    });

    return filteredArray;
  }, [barcodeFieldsToCheck]);

  let divergentData: TableRow[] = [];

  //eslint-disable-next-line
  const id = settings?.fieldSettings.baseFields.address;

  const accuracyData = useMemo(() => {
    //Datable Columns and Rows
    const columnDataRows = [
      [
        $t({ id: 'report.convergent' }),
        $t({ id: 'report.divergent' }),
        $t({ id: 'report.empty' }),
        $t({ id: 'report.invalid' }),
        $t({ id: 'report.accuracy' })
      ]
    ];
    const columnDataColumns: string[] = [];
    const positionDataRows: string[] = [];
    const positionDataColumns = [
      $t({ id: 'report.convergent' }),
      $t({ id: 'report.divergent' }),
      $t({ id: 'report.invalid' }),
      $t({ id: 'report.total' }),
      $t({ id: 'report.accuracy' })
    ];

    //Per Columns Related
    if (filteredData.length) {
      columnDataColumns.push('');

      filteredData.map((fields) => {
        let convergentColumns = 0;
        let divergentColumns = 0;
        let invalidColumns = 0;
        let emptyColumns = 0;

        columnDataColumns.push(fields.field);

        rowsAndColumnsOrError?.rows.map((row) => {
          switch (row[fields.checkField]) {
            case row[fields.field]:
              convergentColumns += 1;
              break;
            case settings?.fieldSettings.resultFields.empty:
            case settings?.fieldSettings.resultFields.null:
              convergentColumns += 1;
              emptyColumns += 1;
              break;
            case settings?.fieldSettings.resultFields.invalid:
              invalidColumns += 1;
              break;
            default:
              divergentColumns += 1;
              divergentData.push(row);
          }
        });

        columnDataRows.push([
          `${convergentColumns}`,
          `${divergentColumns}`,
          `${emptyColumns}`,
          `${invalidColumns}`,
          //eslint-disable-next-line
          `${((convergentColumns * 100) / rowsAndColumnsOrError?.rows.length).toFixed(2)}%`
        ]);
      });

      //Per Position Related
      const fieldState = {
        INVALID: 'inválido',
        CONVERGENT: 'convergente',
        DIVERGENT: 'divergente'
      };

      const positionsCount = {
        convergentPositions: 0,
        divergentPositions: 0,
        invalidPositions: 0,
        totalPositions: 0,
        positionsAccuracy: ''
      };

      //eslint-disable-next-line
      rowsAndColumnsOrError?.rows.map((row) => {
        positionsCount.totalPositions += 1;

        const rowData = filteredData.map((fields) => {
          if (
            row[fields.checkField] == row[fields.field] ||
            row[fields.checkField] == settings?.fieldSettings.resultFields.empty ||
            row[fields.checkField] == settings?.fieldSettings.resultFields.null
          ) {
            return fieldState.CONVERGENT;
          } else if (row[fields.checkField] == settings?.fieldSettings.resultFields.invalid) {
            return fieldState.INVALID;
          } else {
            return fieldState.DIVERGENT;
          }
        });

        if (rowData[0] == fieldState.DIVERGENT || rowData[1] == fieldState.DIVERGENT) {
          positionsCount.divergentPositions += 1;
        } else if (rowData[0] == fieldState.INVALID || rowData[1] == fieldState.INVALID) {
          positionsCount.invalidPositions += 1;
        } else {
          positionsCount.convergentPositions += 1;
        }
      });

      positionsCount.positionsAccuracy = `${(
        (positionsCount.convergentPositions * 100) /
        positionsCount.totalPositions
      ).toFixed(2)}%`;

      Object.keys(positionsCount).forEach((key) => {
        positionDataRows.push(positionsCount[key]);
      });
    }

    return { columnDataRows, columnDataColumns, positionDataRows, positionDataColumns };
  }, [filteredData]);

  return (
    <Box className={classes.container}>
      {loadingSessionData ? (
        <LoadingCircle />
      ) : (
        <Box>
          <Box className={classes.tableContainer}>
            <AccuracyReportTable
              title={$t({ id: 'report.accuracyReport' })}
              subtitle={$t({ id: 'report.positionStatistic' })}
              data={accuracyData}
              type={1}
            />
          </Box>
          <Box className={classes.tableContainer}>
            <AccuracyReportTable
              subtitle={$t({ id: 'report.columnStatistic' })}
              data={accuracyData}
              type={0}
            />
          </Box>
          {divergentData.length ? (
            <Box>
              <DivergencyTable
                title={$t({ id: 'report.divergentPositions' })}
                data={{
                  divergentRows: divergentData,
                  checkFields: filteredData,
                  idField: id ? id : ''
                }}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default AccuracyReport;
