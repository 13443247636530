import { Container, Tab, Tabs, Typography } from '@mui/material';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import React from 'react';
import { useIntl } from 'react-intl';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { appMakeStyles } from 'src/theme/theme';
import FieldsEditor from './FieldsEditor';
import LicensesManagement from './licenses/LicensesManagement';
import ProcessFlow from './processflow/ProcessFlow';
import RestoreSettings from './RestoreSettings';
import TabularDataEditor from './tabulardata/TabularDataEditor';

const useStyles = appMakeStyles(({ spacing, resizableContainer, other }) => ({
  root: {
    ...resizableContainer(1, 2),
    marginLeft: 0
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  tabContainer: {
    margin: spacing(2, 1)
  }
}));

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  className: string;
};
const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`domain-sett-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
};

const tabComponents = {
  ['dSett.domainFields']: FieldsEditor,
  ['tabularData.title']: TabularDataEditor,
  ['processFlow.title']: ProcessFlow,
  licenses: LicensesManagement,
  ['restoreSettings.title']: RestoreSettings
};

const DomainSettings: React.FC<RouteComponentProps> = (props) => {
  const { domain } = useParams<DomainMatchParams>();

  const classes = useStyles();
  const { $t } = useIntl();

  const [tab, setTab] = useStoredState('domainSettingsTabSelection', 0);

  return (
    <Container className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        {$t({ id: 'dSett.title' }, { domain })}
      </Typography>

      <Container>
        <Tabs variant="scrollable" value={tab} onChange={(e, newTab: number) => setTab(newTab)}>
          {Object.keys(tabComponents).map((id) => (
            <Tab label={$t({ id })} key={id} />
          ))}
        </Tabs>

        {Object.values(tabComponents).map((TabComponent, index) => (
          <TabPanel
            value={tab}
            index={index}
            className={classes.tabContainer}
            key={`panel${index}`}
          >
            <TabComponent {...props} />
          </TabPanel>
        ))}
      </Container>
    </Container>
  );
};

export default DomainSettings;
