import environment from 'src/environment';
import developmentConfig from '../config.dev.json';

export const productionConfig = {
  apiKey: 'AIzaSyB5w-qJJyxQD-lhtESw-zsLwmbvuVlThlM',
  authDomain: 'flyid-v2.firebaseapp.com',
  databaseURL: 'https://flyid-v2.firebaseio.com',
  projectId: 'flyid-v2',
  storageBucket: 'flyid-v2.appspot.com',
  messagingSenderId: '941688905293',
  appId: '1:941688905293:web:a45644a4e61c6768b58604',
  measurementId: 'G-60BP28F7HS',
  // Custom keys:
  flightLogsBucket: 'flight-logs-v2',
  profilePicsBucket: 'flyid-profilepics-v2',
  firestoreBackupBucket: 'flyid-backup-v2'
};
export type FirebaseConfig = typeof productionConfig;

export const stagingConfig: FirebaseConfig = {
  apiKey: "AIzaSyBKKMwBt4_OMzyvcZ65nYu7PW1is3Pu9BA",
  authDomain: "flyid-staging-v2-4376c.firebaseapp.com",
  databaseURL: 'https://flyid-staging-v2-4376c.firebaseio.com',
  projectId: "flyid-staging-v2-4376c",
  storageBucket: "flyid-staging-v2-4376c.appspot.com",
  messagingSenderId: "140147989563",
  appId: "1:140147989563:web:98fe3b3be6159a077a501e",
  measurementId: "G-PMYYBC2BC1",
  // Custom keys:
  flightLogsBucket: 'flight-logs-staging-v2',
  profilePicsBucket: 'flyid-profilepics-staging-v2',
  firestoreBackupBucket: 'flyid-backup-staging-v2'
};

let config: FirebaseConfig = productionConfig;
if (!environment.isProduction) {
  if (!environment.isDevelopment) {
    // Using staging project
    config = stagingConfig;
  } else {
    // Using development project
    config = developmentConfig as FirebaseConfig;
  }
}

export default config;
