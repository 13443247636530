import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { DomainSettings } from 'flyid-core/dist/Database/Models';
import { AcquisitionDataFlags } from 'flyid-core/dist/Database/Models/AcquisitionData';
import { parseEpochFromTimestamp, TimestampLike } from 'flyid-core/dist/Util/time';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectCurrentUserProfile, selectTargetCompany } from 'src/redux/selectors/userSelectors';
import { appMakeStyles } from 'src/theme/theme';
import { getDeflated } from 'src/util/helpers/files';
import imageUnavailable from '../../../assets/images/img_unavailable_black.png';
import { fetchStoragePictures } from '../../../redux/actions/userActions';
import LoadingCircle from '../../widgets/LoadingCircle';
import SessionPreview from './SessionPreview';

const useStyles = appMakeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: '10vw'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  image: {
    height: 233,
    width: 350
  },
  headerCell: {
    fontWeight: 'bold'
  }
}));

type Props = {
  reviewableAddresses: {}[];
  totalAddresses: {}[];
  checkFields: string[];
  settings: DomainSettings | undefined;
};

type ReviewState = {
  page: number;
  total: number;
};

const SessionDataReviewer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const $t = intl.$t;
  const history = useHistory();

  const { domain, session } = useParams<SessionMatchParams>();
  const { ui, pictures, userData, profile, targetCompany } = useAppSelector((s) => ({
    profile: selectCurrentUserProfile(s),
    targetCompany: selectTargetCompany(s),
    ui: s.ui,
    pictures: s.acqPictures,
    userData: s.user
  }));

  const [reviewState, setReviewState] = useStateReducer<ReviewState>({ page: 1, total: 0 });
  const [changedData, setChangedData] = useState<{}[]>([]);
  const [image, setImage] = useState<{ src: string; alt: string | undefined }>({
    src: '',
    alt: ''
  });

  const totalAddresses = props.totalAddresses;
  const checkFields = props.checkFields;
  const initialRowData = props.reviewableAddresses
    ? props.reviewableAddresses[reviewState.page - 1]
    : null;

  const handleTextfieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // When textfield value is changed, seach for the specific position to changed the object value
    const newData = changedData.map((obj, index) => {
      if (index === reviewState.page - 1) {
        return { ...obj, [name]: value };
      }
      return obj;
    });
    setChangedData(newData);
  };

  // Returns the table that contains the original values of an specific session address
  // It also has an input for each check field with the with the possibility of updating the value
  function ReviewTable(initialRowValue: {} | null) {
    return (
      <>
        {initialRowValue ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="sessionReviewTable">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell}>
                    {$t({ id: 'sessionReview.fieldName' })}
                  </TableCell>
                  <TableCell align="right" className={classes.headerCell}>
                    {$t({ id: 'sessionReview.acquiredValue' })}
                  </TableCell>
                  <TableCell align="right" className={classes.headerCell}>
                    {$t({ id: 'sessionReview.reviewedValue' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {checkFields.map((field, index) => (
                  <TableRow key={field} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {field}
                    </TableCell>
                    <TableCell align="right">{initialRowValue[field]}</TableCell>
                    <TableCell align="right">
                      <TextField
                        id={`${initialRowValue['address']}${field}`}
                        name={field}
                        placeholder={
                          changedData[reviewState.page - 1]?.[field] ?? initialRowValue[field]
                        }
                        className={classes.textField}
                        onChange={handleTextfieldChange}
                        value={changedData[reviewState.page - 1]?.[field] ?? ''}
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </>
    );
  }

  const handleNextPage = () => {
    if (reviewState.page < reviewState.total + 1) {
      setReviewState({ page: reviewState.page + 1 });
    }
  };

  const handlePreviousPage = () => {
    if (reviewState.page > 1) {
      setReviewState({ page: reviewState.page - 1 });
    }
  };

  // If the user clicks on the picture, it will trigger react viewer for zoom and display options
  const showPicture = () => {
    if (props.settings && profile && initialRowData) {
      const filenames = initialRowData[AcquisitionDataFlags.PictureFilenames] as string | string[];
      dispatch(
        updateUi({
          imageViewer: {
            show: true,
            selectedId: Array.isArray(filenames) ? filenames[0] : filenames
          }
        })
      );
    }
  };

  // Before submiting the data, we verify if there is any field that was not changed
  // If so, the original value of that field is added to the object
  const handleSubmit = () => {
    totalAddresses.forEach((finalData) => {
      props.reviewableAddresses.forEach((originalData: {}, index: number) => {
        if (finalData['address'] === originalData['address']) {
          Object.entries(changedData[index]).forEach(([key, value]) => {
            finalData[key] = value;
          });
        }
      });
    });

    const finalData: { string: {}[] } | {} = {};

    totalAddresses.forEach((address) => {
      const obj = [{ ...address }];
      finalData[address['address']] = obj;
    });

    showDialogConfirmation(finalData);
  };

  // This function show a confirmation dialog, If the user confirms the action, it will trigger the session update function
  const showDialogConfirmation = async (data: { string: {}[] } | {}) => {
    await getDeflated(JSON.stringify(data)).then((deflatedAddresses) => {
      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: 'sessionReview.submitReviewTitle' }),
            message: $t({ id: 'sessionReview.submitReviewMsg' }),
            show: true
          }).setConfirmAction(Actions.UPDATE_SESSION, {
            company: targetCompany!,
            domain: domain,
            session: session,
            userName: `${userData.profile.firstName} ${userData.profile.lastName}`,
            addresses: deflatedAddresses,
            redirectUser: () => history.push(`/domains/${domain}/`)
          })
        })
      );
    });
  };

  // Fetch the image of an address and set its src and alt to a useState
  useEffect(() => {
    if (props.settings && profile && initialRowData && targetCompany) {
      const filenames = initialRowData[AcquisitionDataFlags.PictureFilenames] as string | string[];
      dispatch(
        fetchStoragePictures({
          company: targetCompany,
          domain,
          address: initialRowData['address'] as string,
          checkedAtMs: parseEpochFromTimestamp(initialRowData['checkedAt'] as TimestampLike),
          pictureFilenames: Array.isArray(filenames) ? filenames : [filenames],
          addressField: props.settings?.fieldSettings.baseFields.address,
          intl,
          dontShowImage: true
        })
      );

      const { selectedId } = ui.imageViewer;

      if (selectedId && pictures?.[selectedId]) {
        const selectedPicture = pictures[selectedId];
        if (selectedPicture.isLoaded && !!selectedPicture.src) {
          // Image is loaded, show it
          setImage({ src: selectedPicture.src, alt: selectedPicture.alt });
        }
      }
      // Image data is missing, show unavailable
      setImage({ src: imageUnavailable, alt: $t({ id: 'img.unavailable' }) });
    }
  }, [props.reviewableAddresses, reviewState.page, props.settings]);

  // Prepare components states on load
  useEffect(() => {
    // For each object inside the array "reviewableAddresses", add an empty object to be populated into "changedData" state
    if (props.reviewableAddresses) {
      setChangedData(props.reviewableAddresses.map(() => ({})));
    }

    setReviewState({
      page: 1,
      total: props.reviewableAddresses ? props.reviewableAddresses.length : 0
    });
  }, [props.reviewableAddresses]);

  return (
    <>
      {props.reviewableAddresses && props.checkFields ? (
        <Box className={classes.container}>
          <Box>
            {reviewState.page == reviewState.total + 1 ? (
              changedData.length ? (
                <SessionPreview
                  originalData={props.reviewableAddresses}
                  changedData={changedData}
                  checkFields={checkFields}
                />
              ) : null
            ) : (
              <>
                <Box className={classes.titleContainer}>
                  <Typography variant="h6">
                    {`${$t({ id: 'domain.sessionReview' })} - ${session} - ${initialRowData ? initialRowData['address'] : null}`}
                  </Typography>
                  <Typography variant="h6">
                    {reviewState.page} of {reviewState.total}
                  </Typography>
                </Box>
                <Typography className={classes.subtitle} variant="subtitle2">
                  {$t({ id: 'sessionReview.description' })}
                </Typography>
                <Box className={classes.imageContainer}>
                  <Button onClick={showPicture}>
                    <Box
                      component="img"
                      className={classes.image}
                      alt={image.alt}
                      src={image.src}
                    />
                  </Button>
                </Box>
                {ReviewTable(initialRowData)}
              </>
            )}
            <Box className={classes.buttonContainer}>
              <Button variant="contained" size="small" onClick={handlePreviousPage}>
                {$t({ id: 'previous' })}
              </Button>
              {reviewState.page == reviewState.total + 1 ? (
                <Button onClick={handleSubmit} variant="contained" size="small">
                  {$t({ id: 'sessionReview.finish' })}
                </Button>
              ) : (
                <Button variant="contained" size="small" onClick={handleNextPage}>
                  {reviewState.page === reviewState.total
                    ? $t({ id: 'preview' })
                    : $t({ id: 'next' })}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <LoadingCircle />
      )}
    </>
  );
};

export default SessionDataReviewer;
