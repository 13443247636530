import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Container, Skeleton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { License } from 'flyid-core/dist/Database/Models/License';
import { parseEpochFromTimestamp } from 'flyid-core/dist/Util/time';
import { isEmpty } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectLicenses } from 'src/redux/selectors/dataSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { buildLicenseDescription } from 'src/util/helpers/other';

const useStyles = appMakeStyles(({ spacing, other, form }) => ({
  root: {
    maxWidth: form.maxWidth,
    marginLeft: spacing(1),
    flexGrow: 1
  },
  inputContainer: {
    padding: spacing(0, 1, 4, 2) //T,R,B,L
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  tooltip: {
    alignSelf: 'center'
  },
  licenseSkeleton: {
    height: spacing(4)
  },
  tabContainer: {
    margin: spacing(2, 0)
  }
}));

const MS_IN_A_DAY = 86400000; //1000 * 60 * 60 * 24;

const getLicenseColor = (lic: License) => {
  const lastingDays = (parseEpochFromTimestamp(lic.expDate) - Date.now()) / MS_IN_A_DAY;
  if (lastingDays > 30) return 'success';
  if (lastingDays > 0 && lastingDays <= 30) return 'warning';
  return 'error';
};

const LicensesManagement: React.FC = () => {
  const { domain } = useParams<DomainMatchParams>();
  const { palette, spacing, text } = useAppTheme();
  const classes = useStyles();
  const intl = useIntl();
  const $t = intl.$t;

  const { domainLicenses } = useAppSelector((state) => ({
    domainLicenses: selectLicenses(state, domain)
  }));

  return (
    <Container className={classes.root}>
      <Grid container className={classes.root}>
        <Grid container xs={12} sx={text.subtitle}>
          <Grid xs="auto">
            <Typography variant="h5">{$t({ id: 'dSett.authLicsTitle' })}</Typography>
          </Grid>
          <Grid xs="auto" sx={{ ml: 1 }}>
            <Tooltip
              disableTouchListener
              className={classes.tooltip}
              title={
                <Typography variant="subtitle2">{$t({ id: 'dSett.authLicsSubtitle' })}</Typography>
              }
            >
              <HelpOutlineIcon fontSize="small" sx={{ color: palette.info.dark }} />
            </Tooltip>
          </Grid>
          <Grid xs />
          {domainLicenses && (
            <Grid xs="auto">
              <Button
                component={Link}
                to="editLicenses"
                color="info"
                aria-label={$t({ id: 'edit' })}
              >
                <EditIcon sx={{ mr: 1 }} />
                {$t({ id: 'edit' })}
              </Button>
            </Grid>
          )}
        </Grid>

        {domainLicenses ? (
          isEmpty(domainLicenses) ? (
            <Grid xs={12}>
              <Typography>{$t({ id: 'dSett.noLicSet' })}</Typography>
            </Grid>
          ) : (
            Object.entries(domainLicenses).map(([licKey, lic]) => {
              const id = licKey.substring(0, 8);
              return (
                <Grid container className={classes.inputContainer} key={id}>
                  <Grid xs="auto" sx={{ mr: spacing(1) }}>
                    <ArticleIcon color={getLicenseColor(lic)} />
                  </Grid>
                  <Grid xs>
                    <Typography>{buildLicenseDescription(lic, licKey, intl)}</Typography>
                  </Grid>
                </Grid>
              );
            })
          )
        ) : (
          <Grid xs={12}>
            <Skeleton variant="rectangular" className={classes.licenseSkeleton} animation="wave" />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default LicensesManagement;
