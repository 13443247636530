"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildCase = exports.getEmptiableCaseFields = exports.caseEquals = exports.isCaseComplete = exports.isElseCase = exports.isCaseDataComplete = exports.CaseEntries = exports.CaseRequiredKeys = exports.CaseKeys = exports.Case = exports.CaseTypeValues = exports.CaseTypeKeys = exports.CaseTypes = exports.ELSE_TARGET = void 0;
const LogicalOperator_1 = require("./LogicalOperator");
exports.ELSE_TARGET = '__else__';
var CaseTypes;
(function (CaseTypes) {
    /** Applicable to picture taking only */
    CaseTypes["isPicTaken"] = "__is_pic_taken__";
    /** Applicable to Label Design and MIF with check fields being used*/
    CaseTypes["fieldEqualsExpected"] = "__field_equals_expected__";
    /** Applicable to MIF when type is binary*/
    CaseTypes["binaryMifIsTrue"] = "__is_binary_mif_true__";
    /** Applicable to Label Design and MIF */
    CaseTypes["isNull"] = "__is_null__";
    /** Applicable to Label Design and Logical Block (OR) */
    CaseTypes["isInvalid"] = "__is_invalid__";
    /** Applicable to Custom Markers */
    CaseTypes["hasFlag"] = "__has_flag__";
    /** Applicable when no other condition has matched */
    CaseTypes["else"] = "__else__";
})(CaseTypes = exports.CaseTypes || (exports.CaseTypes = {}));
exports.CaseTypeKeys = Object.keys(CaseTypes);
exports.CaseTypeValues = Object.values(CaseTypes);
class Case {
    constructor() {
        this.targetNodeId = '';
        this.target = '';
        this.isNot = false;
        this.type = CaseTypes.isNull;
    }
}
exports.Case = Case;
exports.CaseKeys = Object.keys(new Case());
exports.CaseRequiredKeys = exports.CaseKeys;
exports.CaseEntries = Object.entries(new Case());
function isCaseDataComplete(value) {
    return (value === null || value === void 0 ? void 0 : value.length) > 0 && value.every((c) => isCaseComplete(c) || (c && (0, LogicalOperator_1.isLogicalOperator)(c)));
}
exports.isCaseDataComplete = isCaseDataComplete;
function isElseCase(value) {
    return value.type === CaseTypes.else;
}
exports.isElseCase = isElseCase;
function isCaseComplete(value) {
    if (!value)
        return false;
    if (isElseCase(value))
        return true;
    const v = value;
    const isNot = typeof v.isNot === 'boolean';
    const type = exports.CaseTypeValues.includes(v.type) && exports.CaseKeys.length === Object.keys(value).length;
    const target = typeof v.target === 'string' && (v.type !== CaseTypes.fieldEqualsExpected || v.target);
    const targetNodeId = typeof v.targetNodeId === 'string' && v.targetNodeId;
    return Boolean(isNot && target && targetNodeId && type);
}
exports.isCaseComplete = isCaseComplete;
function caseEquals(c1, c2) {
    return c2 && c1.type === c2.type && c1.target === c2.target && c1.isNot === c2.isNot;
}
exports.caseEquals = caseEquals;
const getEmptiableCaseFields = (data) => (isElseCase(data) ? ['target', 'targetNodeId'] : []);
exports.getEmptiableCaseFields = getEmptiableCaseFields;
const buildCase = (target, targetNodeId, type, isNot = false) => ({ target, targetNodeId, type, isNot });
exports.buildCase = buildCase;
