import { getTypedElementId, getTypedId } from 'flyid-core/dist/Util/processFlow';
import { MapOf } from 'flyid-core/dist/Util/types';
import { cloneDeep } from 'lodash';
import { ComponentType } from 'react';
import { Node, NodeProps } from 'reactflow';
import AutoFillEditor from 'src/components/management/domainsettings/processflow/editors/autofill/AutoFillEditor';
import ConditionalEditor from 'src/components/management/domainsettings/processflow/editors/conditional/ConditionalEditor';
import LabelDesignEditor from 'src/components/management/domainsettings/processflow/editors/labeldesign/LabelDesignEditor';
import LogicalBlockEditor from 'src/components/management/domainsettings/processflow/editors/LogicalBlockEditor';
import ManualInputFieldEditor from 'src/components/management/domainsettings/processflow/editors/ManuaInputFieldEditor';
import PictureTakingEditor from 'src/components/management/domainsettings/processflow/editors/PictureTakingEditor';
import AutoFillNode from 'src/components/management/domainsettings/processflow/nodes/AutoFillNode';
import ConditionalNode from 'src/components/management/domainsettings/processflow/nodes/ConditionalNode';
import EndNode from 'src/components/management/domainsettings/processflow/nodes/EndNode';
import LabelDesignNode from 'src/components/management/domainsettings/processflow/nodes/LabelDesignNode';
import LogicalBlockNode from 'src/components/management/domainsettings/processflow/nodes/LogicalBlockNode';
import ManualInputFieldNode from 'src/components/management/domainsettings/processflow/nodes/ManualInputFieldNode';
import { TakePictureNode } from 'src/components/management/domainsettings/processflow/nodes/PictureTakingNode';
import StartNode from 'src/components/management/domainsettings/processflow/nodes/StartNode';
import { Undefinable } from 'tsdef';
import { getPureId } from './common';
import { parentNodeTypes } from './node';
import {
  BaseNodeData,
  CommonNodeData,
  EditorProps,
  FlowNodeFront,
  SpecificDataTypesListable,
  TypedNode
} from './types';
import {
  FlowNode,
  NodeType,
  getNodeTypeFromStringValue
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LabelDesign } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import { ManualInputField } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import { PictureTaking } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/PictureTaking';
import { AutoFillData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/AutoFillData';
import { CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import { LogicalBlock } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalBlock';
import { ProcessFlowSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import CustomMarkerNode from 'src/components/management/domainsettings/processflow/nodes/CustomMarkerNode';
import CustomMarkerEditor from 'src/components/management/domainsettings/processflow/editors/CustomMarkerEditor';
import { CustomMarker } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';
import { Rotation } from 'flyid-core/dist/Util/geometry';

export const nodeTypes: Record<NodeType, ComponentType<NodeProps>> = {
  [NodeType.Start]: StartNode,
  [NodeType.LabelDesign]: LabelDesignNode,
  [NodeType.ManualInputField]: ManualInputFieldNode,
  [NodeType.TakePicture]: TakePictureNode,
  [NodeType.AutoFillData]: AutoFillNode,
  [NodeType.Conditional]: ConditionalNode,
  [NodeType.LogicalBlock]: LogicalBlockNode,
  [NodeType.CustomMarker]: CustomMarkerNode,
  [NodeType.End]: EndNode
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const NodeEditor: Record<NodeType, ComponentType<EditorProps<any>> | undefined> = {
  [NodeType.Start]: undefined,
  [NodeType.LabelDesign]: LabelDesignEditor,
  [NodeType.ManualInputField]: ManualInputFieldEditor,
  [NodeType.TakePicture]: PictureTakingEditor,
  [NodeType.AutoFillData]: AutoFillEditor,
  [NodeType.Conditional]: ConditionalEditor,
  [NodeType.LogicalBlock]: LogicalBlockEditor,
  [NodeType.CustomMarker]: CustomMarkerEditor,
  [NodeType.End]: undefined
};

export const NodeData: Record<NodeType, Undefinable<SpecificDataTypesListable>> = {
  [NodeType.Start]: undefined,
  [NodeType.LabelDesign]: new LabelDesign(),
  [NodeType.ManualInputField]: new ManualInputField(),
  [NodeType.TakePicture]: new PictureTaking(),
  [NodeType.AutoFillData]: [] as AutoFillData[],
  [NodeType.Conditional]: [] as CaseData[],
  [NodeType.LogicalBlock]: new LogicalBlock(),
  [NodeType.CustomMarker]: new CustomMarker(),
  [NodeType.End]: undefined
};

export const getNewNodeData = (type: NodeType) => ({
  specificData: cloneDeep(NodeData[type]),
  editor: NodeEditor[type]
});

export const settToFrontNodes = (
  settings: ProcessFlowSettings,
  getBaseNodeData: (node: FlowNodeFront, isStartEndNode: boolean) => BaseNodeData
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createNodeFromSettings = (nodeId: string, node: FlowNode, isParent = false) => {
    const nodeFront: FlowNodeFront = { id: nodeId, ...node };

    const isStartOrEndNode = node.type === NodeType.Start || node.type === NodeType.End;
    let specificData: Undefinable<SpecificDataTypesListable>;
    switch (node.type) {
      case NodeType.AutoFillData:
        specificData = settings.autoFillData[nodeId];
        break;
      case NodeType.Conditional:
        specificData = settings.conditionals[nodeId] as CaseData[];
        break;
      case NodeType.LabelDesign:
        specificData = settings.labelDesigns[nodeId];
        break;
      case NodeType.LogicalBlock:
        specificData = settings.logicalBlocks[nodeId];
        break;
      case NodeType.ManualInputField:
        specificData = settings.manualInputFields[nodeId];
        break;
      case NodeType.TakePicture:
        specificData = settings.picTaking[nodeId];
        break;
      case NodeType.CustomMarker:
        specificData = settings.customMarkers[nodeId];
        break;
      default:
        specificData = undefined;
    }

    const data: CommonNodeData = {
      editor: NodeEditor[node.type],
      baseNodeData: getBaseNodeData(nodeFront, isStartOrEndNode),
      specificData
    };

    const nodeData: TypedNode = {
      ...node,
      id: getTypedId(nodeFront),
      data,
      dragHandle: '.custom-drag-handle'
    };

    // If is a child node, complement data
    if (node.parent) {
      const parentNode = settings.nodes[node.parent];
      if (parentNode) {
        nodeData.parentNode = getTypedElementId(node.parent, parentNode.type);
        nodeData.extent = 'parent';
        nodeData.draggable = false;
        nodeData.deletable = false;
        nodeData.data.parent = nodeData.parentNode;
      }
    }
    return nodeData;
  };
  const parentNodeIds: string[] = [];

  // Make sure parent nodes are added first for proper functioning of Reactflow
  const retNodes = Object.entries(settings.nodes)
    .filter(([, nodeData]) => parentNodeTypes.includes(nodeData.type))
    .map(([nodeId, nodeData]) => {
      parentNodeIds.push(nodeId);
      return createNodeFromSettings(nodeId, nodeData, true);
    });

  // Then add other nodes
  const ret = retNodes.concat(
    Object.entries(settings.nodes)
      .filter(([nodeId]) => !parentNodeIds.includes(nodeId))
      .map(([nodeId, nodeData]) => createNodeFromSettings(nodeId, nodeData))
  );
  return ret;
};
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const frontToSettNodes = (nodes: Node<CommonNodeData>[]) =>
  nodes.reduce((obj, n) => {
    obj[getPureId(n.id)!] = {
      position: n.position,
      type: getNodeTypeFromStringValue(n.type as string)!,
      parent: getPureId(n.parentNode),
      rotation: n.data.baseNodeData.rotation ?? Rotation.State.NONE
    };
    return obj;
  }, {} as MapOf<FlowNode>);
/* eslint-enable @typescript-eslint/no-non-null-assertion */
