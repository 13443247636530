import type { useState } from 'react';
import type { GlobalsSlice, GlobalsState } from 'src/redux/reducers/globalsReducer';
import { GlobalStateKey, updateGlobals } from 'src/redux/reducers/globalsReducer';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { getGlobalDefault } from 'src/redux/selectors/userSelectors';

/**
 * This state is backed by redux through {@link GlobalsSlice}.
 * Use it when there is a requirement to use a global state with behavior similar to {@link useState}.
 */
const useGlobalState = <Key extends GlobalStateKey>(key: Key) => {
  type Value = GlobalsState[Key];

  const dispatch = useAppDispatch();
  const state = useAppSelector((s) => s);

  const currValue = useAppSelector((s) => s.globals[key] ?? getGlobalDefault(key, state));
  const setStateFun = (value: Value) => dispatch(updateGlobals({ [key]: value }));

  return [currValue, setStateFun] as [Value, typeof setStateFun];
};

export default useGlobalState;
