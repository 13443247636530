import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionData, ProcessFlowState } from '../types/processFlowTypes';

const processFlowSlice = createSlice({
  name: 'processFlow',
  initialState: new ProcessFlowState(),
  reducers: {
    setConnectingData: (state, action: PayloadAction<ConnectionData>) => {
      Object.assign(state, action.payload);
    }
  }
});

export const { setConnectingData } = processFlowSlice.actions;

export default processFlowSlice.reducer;
