import { Vertex } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Vertex';
import { Position } from 'reactflow';

export enum BoxActionArea {
  UP,
  RIGHT,
  DOWN,
  LEFT
}

export const TooltipOppositePosition: Record<Position, 'bottom' | 'left' | 'top' | 'right'> = {
  [Position.Top]: 'bottom',
  [Position.Right]: 'left',
  [Position.Bottom]: 'top',
  [Position.Left]: 'right'
};

export const PositionByBoxActionArea: Record<BoxActionArea, Position> = {
  [BoxActionArea.UP]: Position.Top,
  [BoxActionArea.RIGHT]: Position.Right,
  [BoxActionArea.DOWN]: Position.Bottom,
  [BoxActionArea.LEFT]: Position.Left
};

export const OppositePosition: Record<Position, Position> = {
  [Position.Top]: Position.Bottom,
  [Position.Right]: Position.Left,
  [Position.Bottom]: Position.Top,
  [Position.Left]: Position.Right
};

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * Returns the mouse position relative to a box center
 *
 * @param mouseX mouseX (relative to box origin)
 * @param mouseY mouseY (relative to box origin)
 * @param boxWidth Box width
 * @param boxHeight box Height
 */
export const getMouseActionArea = (
  mouseX: number,
  mouseY: number,
  boxWidth: number,
  boxHeight: number
): BoxActionArea => {
  const centerX = boxWidth / 2;
  const yl1 = (boxHeight / boxWidth) * mouseX;
  const yl2 = boxHeight - yl1;
  if (mouseX < centerX) {
    return mouseY > yl1
      ? mouseY < yl2
        ? BoxActionArea.LEFT
        : BoxActionArea.DOWN
      : BoxActionArea.UP;
  } else {
    return mouseY > yl2
      ? mouseY < yl1
        ? BoxActionArea.RIGHT
        : BoxActionArea.DOWN
      : BoxActionArea.UP;
  }
};

type BezierDebugData = {
  firstBezier: Vertex;
  secondBezier: Vertex;
};

export const getCustomBezierPath = (input: {
  sourceX: number;
  sourceY: number;
  sourcePosition: Position;
  targetX: number;
  targetY: number;
  /* eslint-disable @typescript-eslint/no-unused-vars */
  targetPosition?: Position;
}): [string, BezierDebugData] => {
  const { sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition } = input;

  let isVertical = false;
  const firstBezier: Vertex = { x: sourceX, y: sourceY };
  if (sourcePosition === Position.Top) {
    firstBezier.y -= 24;
    isVertical = true;
  } else if (sourcePosition === Position.Right) {
    firstBezier.x += 24;
  } else if (sourcePosition === Position.Bottom) {
    firstBezier.y += 24;
    isVertical = true;
  } else {
    firstBezier.x -= 24;
  }

  const secondBezier: Vertex = {
    x: !isVertical
      ? sourcePosition === Position.Left
        ? Math.min(firstBezier.x - (targetX - firstBezier.x) / 2, Math.max(targetX, firstBezier.x))
        : Math.max(firstBezier.x + (firstBezier.x - targetX) / 2, Math.min(targetX, firstBezier.x))
      : (sourceX + targetX * 3) / 4,
    y: isVertical
      ? sourcePosition === Position.Top
        ? Math.min(firstBezier.y - (targetY - firstBezier.y) / 2, Math.max(targetY, firstBezier.y))
        : Math.max(firstBezier.y + (firstBezier.y - targetY) / 2, Math.min(targetY, firstBezier.y))
      : (sourceY + targetY * 3) / 4
  };

  const bezierDescription = `M ${sourceX},${sourceY}
     C ${firstBezier.x}, ${firstBezier.y}
       ${secondBezier.x}, ${secondBezier.y}
       ${targetX}, ${targetY}`;

  return [bezierDescription, { firstBezier, secondBezier }];
};

export function vertexToRect(firstVertex?: Vertex, secondVertex?: Vertex): Rect {
  const rect = { x: 0, y: 0, width: 0, height: 0 };
  if (firstVertex && secondVertex) {
    rect.x = Math.min(firstVertex.x, secondVertex.x);
    rect.y = Math.min(firstVertex.y, secondVertex.y);
    rect.width = Math.abs(firstVertex.x - secondVertex.x);
    rect.height = Math.abs(firstVertex.y - secondVertex.y);
  }

  return rect;
}

export function limitDimenKeepRatio(
  origWidth: number,
  origHeight: number,
  maxWidth: number,
  maxHeight: number
) {
  let width = origWidth;
  let height = origHeight;
  const ratio = width / height;

  if (ratio > 1 && width > maxWidth) {
    width = maxWidth;
    height *= width / origWidth;
  } else if (ratio < 1 && height > maxHeight) {
    height = maxHeight;
    width *= height / origHeight;
  }

  return { width, height };
}
