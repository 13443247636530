import KeyboardIcon from '@mui/icons-material/Keyboard';
import { ManualInputField } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import React, { memo } from 'react';
import { isSpecificDataEqual } from 'src/util/processFlow/node';
import { ProcessNodeType } from 'src/util/processFlow/types';
import BaseNode, { Content, Handles } from './BaseNode';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';

export const ManualInputFieldNode: React.FC<ProcessNodeType<ManualInputField>> = (props) => {
  const mif = props.data.specificData;

  const content: Content = {
    iconStart: KeyboardIcon,
    titleId: 'processFlow.ManualInputField',
    description: mif?.field
  };

  const handles: Handles = {
    inputHandles: [HandleType.MULTIPLE],
    outputHandles: [HandleType.SINGLE]
  };

  return (
    <div>
      {/* Content and basic handlers */}
      <BaseNode
        selected={props.selected}
        content={content}
        handles={handles}
        id={props.id}
        {...props.data.baseNodeData}
      />
    </div>
  );
};

export default memo(ManualInputFieldNode, isSpecificDataEqual);
