import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FirestoreState,
  SetAuthProviderData,
  UpdateAPIKeysData,
  UpdateAuthLicensesData,
  UpdateUserProfilesData
} from '../types/firestoreTypes';
import type {
  UpdateAuthDomains,
  UpdateCompaniesData,
  UpdateDomainSettingsData
} from './../types/firestoreTypes';

const firestoreSlice = createSlice({
  name: 'firestore',
  initialState: new FirestoreState(),
  reducers: {
    // Authentication provider data
    setAuthProvider: (state, action: PayloadAction<SetAuthProviderData>) => {
      state.authProviders = Object.assign(state.authProviders ?? {}, {
        areAuthProvidersLoaded: true,
        providers: action.payload
      });
    },
    // Domain data
    updateDomainSettings: (state, action: PayloadAction<UpdateDomainSettingsData>) => {
      state.domainSettings = Object.assign(state.domainSettings ?? {}, action.payload);
    },
    // Moderator and KeyUser data
    updateUserProfiles: (state, action: PayloadAction<UpdateUserProfilesData>) => {
      state.userProfiles = Object.assign(state.userProfiles ?? {}, action.payload);
    },
    updateAuthLicenses: (state, action: PayloadAction<UpdateAuthLicensesData>) => {
      state.authLicenses = Object.assign(state.authLicenses ?? {}, action.payload);
    },
    updateAPIKeys: (state, action: PayloadAction<UpdateAPIKeysData>) => {
      state.apiKeys = Object.assign(state.apiKeys ?? {}, action.payload);
    },
    updateCompaniesData: (state, action: PayloadAction<UpdateCompaniesData>) => {
      state.companiesData = Object.assign(state.companiesData ?? {}, action.payload);
    },
    updateAuthDomains: (state, action: PayloadAction<UpdateAuthDomains>) => {
      state.authDomains = Object.assign(state.authDomains ?? {}, action.payload);
    }
  }
});

export const {
  setAuthProvider,
  updateDomainSettings,
  updateUserProfiles,
  updateAuthLicenses,
  updateAPIKeys,
  updateCompaniesData,
  updateAuthDomains
} = firestoreSlice.actions;

export default firestoreSlice.reducer;
