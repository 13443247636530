import { cx } from '@emotion/css';
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { getDoc } from 'firebase/firestore';
import { UserPublic } from 'flyid-core/dist/Database/Models';
import { getCompanyDoc } from 'flyid-core/dist/Util/database';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { buildDocumentRef } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import useGlobalState from 'src/hooks/useGlobalState';
import useStateReducer from 'src/hooks/useStateReducer';
import { addUser, AddUserParams } from 'src/redux/actions/userActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import {
  selectAuthDomains,
  selectCurrentUserProfile,
  selectTargetCompany,
  selectUserProfiles
} from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import cidLogo from '../../assets/images/logo/monograma_cid.png';
import fidLogo from '../../assets/images/logo/monograma_flyid.png';
import { groupByCompany } from '../utils/GroupBy';
import LoadingButton from '../widgets/LoadingButton';
import LogoTooltip from '../widgets/LogoTooltip';
import { filterModerators } from 'src/util/helpers/user';
import { selectCompanyExhibitionName } from 'src/redux/selectors/dataSelectors';
import { isEmpty } from 'lodash';

const useStyles = appMakeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  title: {
    color: theme.other.grey.dark
  },
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0,
    maxWidth: '800px'
  },
  hidden: {
    display: 'none'
  },
  countidIconRootClass: {
    backgroundColor: theme.palette.primary.main
  },
  flyidIconRootClass: {
    backgroundColor: theme.palette.primary.dark
  }
}));

const isAllowedEmailCharacter = (str: string) => /^[a-zA-Z0-9+_.-]*$/.test(str);
const getFakeEmailDomain = (company: string) =>
  `@${company?.replace(new RegExp('_', 'g'), '.')}.flyid.com`;

type UserFormData = {
  company: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  permissions: string[];
  authDomains: string[];
  email: string;
  password: string;
  confirmation: string;
  lacksInstitutionalEmail: boolean;
};

const initialUserData: UserFormData = {
  company: '',
  firstName: '',
  lastName: '',
  employeeId: '',
  permissions: [],
  authDomains: [],
  email: '',
  password: '',
  confirmation: '',
  lacksInstitutionalEmail: false
};
const initialPermissions = {
  missingPermissions: false,
  missingDomains: false,
  showConfirmation: false
};

const KEY_PILOT = 'pilot';
const KEY_CHECKER = 'checker';
const KEY_ASSISTANT = 'assistant';
const KEY_MODERATOR = 'moderator';

type UserPermissionsData = typeof initialPermissions;
type State = UserFormData & UserPermissionsData;

const getEmailSuggestion = (firstName: string, lastName: string) =>
  firstName && lastName ? `${firstName.toLowerCase()}.${lastName.toLowerCase()}` : '';

const AddUser: React.FC = () => {
  const [state, _setState] = useStateReducer<State>(
    Object.assign({}, initialUserData, initialPermissions)
  );

  const { $t } = useIntl();
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const { usingSSO } = useAuth();

  const { profile, ui, userProfiles, targetCompany, authDomains, companyName } = useAppSelector(
    (s) => {
      const targetCompany = selectTargetCompany(s);
      return {
        targetCompany,
        ui: s.ui,
        companyName: selectCompanyExhibitionName(targetCompany, s),
        profile: selectCurrentUserProfile(s),
        userProfiles: selectUserProfiles(s),
        authDomains: selectAuthDomains(s)
      };
    }
  );

  /* Key user configuration */
  const [targetParentUid, setTargetParentUid] = useGlobalState('targetParentUid');

  /** Apply effects to state setting */
  const setState = (data: Partial<State>) => {
    const withEffects = { ...data };
    if (data.permissions?.includes(KEY_MODERATOR) && state.lacksInstitutionalEmail) {
      withEffects.lacksInstitutionalEmail = false;
    }
    _setState(withEffects);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!profile || !targetCompany) return;

    const missingDomains = !state.authDomains.length;
    const missingPermissions = !state.permissions.length;
    if (missingDomains || missingPermissions) {
      setState({ missingPermissions, missingDomains });
      return;
    }

    // 6 digit password check if pilot
    if (!usingSSO) {
      const userPerm = state.permissions;
      const isPilotOrChecker = userPerm.includes(KEY_PILOT) || userPerm.includes(KEY_CHECKER);
      if (state.password) {
        if (isPilotOrChecker && !/^\d{6}$/.test(state.password)) {
          dispatch(
            updateUi({
              snackbar: {
                message: $t({ id: 'err.pilotPassword' }),
                severity: 'error',
                show: true
              }
            })
          );
          return;
        }
        if (state.password !== state.confirmation) {
          dispatch(
            updateUi({
              snackbar: {
                message: $t({ id: 'err.pwNoMatch' }),
                severity: 'error',
                show: true
              }
            })
          );
          return;
        }
      }
    }

    const userData: AddUserParams = {
      company: targetCompany,
      email: state.email + (state.lacksInstitutionalEmail ? getFakeEmailDomain(targetCompany) : ''),
      employeeId: state.employeeId,
      firstName: state.firstName,
      lastName: state.lastName,
      authDomains: state.authDomains,
      usesAuthProvider: usingSSO,
      //Permissions
      assistant: state.permissions.includes(KEY_ASSISTANT),
      pilot: state.permissions.includes(KEY_PILOT),
      checker: state.permissions.includes(KEY_CHECKER),
      moderator: false,
      lacksInstitutionalEmail: state.lacksInstitutionalEmail
    };

    if (!usingSSO) {
      userData.password = state.password;
      userData.confirmation = state.confirmation;
    }

    dispatch(addUser(userData));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { firstName, lastName } = state;
    const data: Partial<State> = { [event.target.name]: event.target.checked };
    if (event.target.name === 'lacksInstitutionalEmail') {
      data.email = Boolean(event.target.checked && firstName && lastName)
        ? getEmailSuggestion(firstName, lastName)
        : '';
    }
    setState(data);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const showConfirmation =
      e.target.name === 'password'
        ? e.target.value.length > 0
          ? true
          : false
        : state.showConfirmation;

    const sideEffects: Partial<State> = {};
    if (state.lacksInstitutionalEmail) {
      switch (e.target.name as keyof State) {
        case 'email': {
          if (!isAllowedEmailCharacter(e.target.value)) {
            updateUi({
              snackbar: {
                message: $t({ id: 'err.invalidEmailCharacter' }),
                severity: 'error',
                show: true
              }
            });
            return;
          }
          break;
        }
        case 'firstName':
          if (isAllowedEmailCharacter(e.target.value)) {
            sideEffects.email = getEmailSuggestion(e.target.value, state.lastName);
          }
          break;
        case 'lastName':
          if (isAllowedEmailCharacter(e.target.value)) {
            sideEffects.email = getEmailSuggestion(state.firstName, e.target.value);
          }
          break;
        default:
          break;
      }
    }

    setState({
      [e.target.name]: e.target.value,
      ...sideEffects,
      showConfirmation
    });
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const companyModerators = useMemo(() => {
    if (userProfiles && targetCompany) {
      const companyProfiles = groupByCompany(userProfiles)[targetCompany];
      return filterModerators(companyProfiles) ?? [];
    }
    return [];
  }, [targetCompany, userProfiles]);

  const isKeyUserMissingMods = !!profile?.keyUser && isEmpty(companyModerators);
  useEffect(() => {
    // Make sure state values are properly set when company/profile changes,
    // which reflects on isKeyUserMissingMods
    if (isKeyUserMissingMods) {
      setState({
        lacksInstitutionalEmail: false,
        authDomains: [],
        permissions: [KEY_MODERATOR]
      });
    }
  }, [isKeyUserMissingMods]);

  const isModSelected = state.permissions.includes(KEY_MODERATOR);
  const permissionsRef = profile?.keyUser
    ? [KEY_PILOT, KEY_CHECKER, KEY_ASSISTANT, KEY_MODERATOR]
    : [KEY_PILOT, KEY_CHECKER, KEY_ASSISTANT];

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={cx(classes.margin, classes.title)}>
          {profile?.keyUser
            ? $t({ id: 'addUsr.kuTitle' }, { companyName })
            : $t({ id: 'addUsr.title' })}
        </Typography>
        {profile?.keyUser && !targetCompany ? (
          <Typography variant="subtitle1" sx={theme.text.subtitle}>
            {$t({ id: 'keyUserCompany.warning' })}
          </Typography>
        ) : (
          <>
            {profile?.keyUser && !isKeyUserMissingMods ? (
              <FormControl required fullWidth className={classes.margin}>
                <InputLabel id="parentUid-label">{$t({ id: 'moderator' })}</InputLabel>
                <Select
                  disabled={!targetCompany}
                  labelId="parentUid-label"
                  id="parentUid"
                  name="parentUid"
                  value={targetParentUid}
                  onChange={(e) => setTargetParentUid(e.target.value)}
                  input={<OutlinedInput label={$t({ id: 'parentUid' })} />}
                  MenuProps={theme.select.getMenuProps()}
                >
                  {companyModerators.map((mod) => {
                    return (
                      <MenuItem key={Object.keys(mod)[0]} value={Object.keys(mod)[0]}>
                        <ListItemText
                          primary={`${Object.values(mod)[0].firstName} ${Object.values(mod)[0].lastName}`}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
            <TextField
              fullWidth
              required
              id="firstName"
              name="firstName"
              type="text"
              label={$t({ id: 'firstName' })}
              value={state.firstName}
              onChange={handleTextChange}
              className={classes.margin}
              autoComplete="given-name"
              autoFocus
            />
            <TextField
              fullWidth
              required
              id="lastName"
              name="lastName"
              type="text"
              label={$t({ id: 'lastName' })}
              autoComplete="family-name"
              value={state.lastName}
              onChange={handleTextChange}
              className={classes.margin}
            />
            <TextField
              fullWidth
              required
              id="employeeId"
              name="employeeId"
              type="text"
              label={$t({ id: 'employeeId' })}
              value={state.employeeId}
              onChange={handleTextChange}
              autoComplete="off"
              className={classes.margin}
            />
            <FormControl
              required
              fullWidth
              className={classes.margin}
              error={state.missingPermissions}
            >
              <InputLabel id="permissions-label">{$t({ id: 'permissions' })}</InputLabel>
              <Select
                labelId="permissions-label"
                id="permissions"
                name="permissions"
                multiple
                value={state.permissions}
                onChange={handleMultipleSelectChange}
                onClose={() => {
                  if (state.missingPermissions) {
                    setState({
                      missingPermissions: !!state.permissions.length
                    });
                  }
                }}
                input={<OutlinedInput label={$t({ id: 'permissions' })} />}
                renderValue={(selected) => selected.map((perm) => $t({ id: perm })).join(', ')}
                MenuProps={theme.select.getMenuProps()}
              >
                {/* If is key user but no moderator is available, force creation of a moderator. */}
                {permissionsRef.map((perm) => (
                  <MenuItem
                    key={perm}
                    value={perm}
                    disabled={isKeyUserMissingMods && perm !== KEY_MODERATOR}
                  >
                    <Checkbox checked={state.permissions.indexOf(perm) > -1} />
                    <ListItemText primary={$t({ id: perm })} />
                    {perm === KEY_CHECKER && (
                      <LogoTooltip
                        tooltip={$t({ id: 'grantsCountidAccess' })}
                        logo={cidLogo}
                        iconRootClass={classes.countidIconRootClass}
                      />
                    )}
                    {perm === KEY_PILOT && (
                      <LogoTooltip
                        tooltip={$t({ id: 'grantsFlyidAccess' })}
                        logo={fidLogo}
                        iconRootClass={classes.flyidIconRootClass}
                      />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {!isModSelected && (
              <FormControl
                required
                fullWidth
                className={classes.margin}
                error={state.missingDomains}
              >
                <InputLabel id="authdomains-label">{$t({ id: 'authDomains' })}</InputLabel>
                <Select
                  labelId="authdomains-label"
                  id="authdomains"
                  name="authDomains"
                  multiple
                  value={state.authDomains}
                  onChange={handleMultipleSelectChange}
                  onClose={() => {
                    if (state.missingDomains) {
                      setState({
                        missingDomains: !!state.authDomains.length
                      });
                    }
                  }}
                  input={<OutlinedInput label={$t({ id: 'authDomains' })} />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={theme.select.getMenuProps()}
                >
                  {(authDomains ?? []).map((domain) => (
                    <MenuItem key={domain} value={domain}>
                      <Checkbox checked={state.authDomains.indexOf(domain) > -1} />
                      <ListItemText primary={domain} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!usingSSO && !isKeyUserMissingMods && !isModSelected && (
              <FormControl fullWidth className={classes.margin}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="lacksInstitutionalEmail"
                        checked={state.lacksInstitutionalEmail}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {$t({ id: 'addUsr.lacksInstitutionalEmail' })}
                      </Typography>
                    }
                  />
                </FormGroup>
              </FormControl>
            )}
            <TextField
              fullWidth={!usingSSO || !state.lacksInstitutionalEmail}
              inputProps={{ autoComplete: 'off' }}
              required
              id="email"
              name="email"
              type={!usingSSO || state.lacksInstitutionalEmail ? 'text' : 'email'}
              label={$t({ id: 'addUsr.email' })}
              value={state.email}
              onChange={handleTextChange}
              className={classes.margin}
            />
            {!usingSSO && (
              <>
                {!isModSelected && state.lacksInstitutionalEmail && targetCompany ? (
                  <TextField
                    disabled={true}
                    value={getFakeEmailDomain(targetCompany)}
                    sx={{ mb: 1 }}
                  />
                ) : null}
                <TextField
                  fullWidth
                  inputProps={{ autoComplete: 'new-password' }}
                  id="password"
                  name="password"
                  type="password"
                  label={$t({ id: 'addUsr.pw' })}
                  value={state.password}
                  onChange={handleTextChange}
                  className={classes.margin}
                />
                <TextField
                  fullWidth
                  inputProps={{ autoComplete: 'new-password' }}
                  id="confirmation"
                  name="confirmation"
                  type="password"
                  label={$t({ id: 'addUsr.pwconf' })}
                  value={state.confirmation}
                  onChange={handleTextChange}
                  required={state.showConfirmation}
                  className={cx(classes.margin, state.showConfirmation ? null : classes.hidden)}
                />
              </>
            )}
            <LoadingButton
              isLoading={ui.loadingButton.isAddUserLoading}
              content={$t({ id: 'submit' })}
              type="submit"
            />
          </>
        )}
      </form>
    </Container>
  );
};

export default AddUser;
