import { CaseData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Case';
import {
  HandleType,
  NodeType
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LogicalOperator } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LogicalOperator';
import { Rotation } from 'flyid-core/dist/Util/geometry';
import { generateDoubleId } from 'flyid-core/dist/Util/processFlow';
import { cloneDeep } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Node } from 'reactflow';
import { useAppReactFlow } from 'src/hooks/useAppReactFlow';
import useOnceEffect from 'src/hooks/useOnceEffect';
import { filterElementsByType, getIncomersById } from 'src/util/processFlow/common';
import { createEdgeFrontBetweenNodes } from 'src/util/processFlow/edges';
import {
  arePropsEqual,
  isCommonNodeDataEqual,
  isSpecificDataEqual
} from 'src/util/processFlow/node';
import {
  CommonNodeData,
  LogicalBlockParent,
  ProcessNodeType,
  SpecificDataTypesListable
} from 'src/util/processFlow/types';
import { Handles } from './BaseNode';
import ParentNode from './ParentNode';

export const LogicalBlockNode: React.FC<ProcessNodeType<LogicalBlockParent>> = (props) => {
  const logicalBlockData = props.data.specificData;
  if (!logicalBlockData) throw Error('Missing specific data for LogicalBlockNode');

  const { $t } = useIntl();
  const { addNodes, setNodes, getNodes, addEdges, getEdges, deleteElements } = useAppReactFlow();
  const [isInit, setIsInit] = useState(false);

  const {
    operation,
    contentChildrenIds,
    outputNodeId: logicalBlockOutputNodeId
  } = logicalBlockData;

  const handles: Handles = {
    inputHandles: [HandleType.MULTIPLE],
    outputHandles: []
  };

  // Trigger changes when operation changes
  useEffect(() => {
    if (!isInit) return;
    // It was an OR and is now an AND, because LogicalBlock is always initialized with operation OR
    if (operation === LogicalOperator.AND) {
      // An output node already exists, just leave it be.
      if (logicalBlockOutputNodeId) return;

      const allNodes = getNodes();
      const allEdges = getEdges();
      const allElements = [...allNodes, ...allEdges];

      const allConditionalNodes = filterElementsByType(allNodes, NodeType.Conditional);
      const outputConditionlNodes = allConditionalNodes.filter((n) => {
        // Get only conditional nodes which have incomers from contentChildrenIds
        const incomerIds = getIncomersById(n.id, allElements).map((inNode) => inNode.id);
        return contentChildrenIds.filter((childId) => incomerIds.includes(childId)).length > 0;
      });

      // 1. If there are more than one output nodes
      // Leave the external conditional node but remove all edges with children of this logical block.
      if (outputConditionlNodes.length > 1) {
        const edgesToRemove = getEdges().filter((edge) => {
          // Check if the source of the edge is a child node of the logical block
          const isSourceInsideBlock = contentChildrenIds.includes(edge.source);
          // Check that the target of the edge is the external conditional node
          const isTargetExternalConditional = edge.targetNode === logicalBlockOutputNodeId;
          // Returns true if the edge connects a child of the logical block to the external conditional node
          return isSourceInsideBlock && isTargetExternalConditional;
        });
        // Remove the identified edges
        edgesToRemove.forEach((edgeToRemove) => {
          deleteElements({ edges: [{ id: edgeToRemove.id }] });
        });
      } // 2. If all inputs of a single output conditional node are from this logical block, attach it.
      else if (outputConditionlNodes.length === 1) {
        // Check that all entries in the conditional node are from this logic block by checking if
        // all the origins of the output node edges are within the logical block
        const theOnlyOutputConditionalNode = outputConditionlNodes[0];
        const allInputsFromBlock = getEdges()
          .filter((edge) => edge.targetNode === theOnlyOutputConditionalNode)
          .every((edge) => contentChildrenIds.includes(edge.source));
        // If all the inputs are from the logic block and the conditional node is already present, connect it to the logic block
        if (allInputsFromBlock) {
          // Make the conditional node a child of the logical block
          setNodes((ns) =>
            ns.map((n) => {
              if (n.id === theOnlyOutputConditionalNode.id) {
                n = {
                  ...n,
                  position: { x: 0, y: 0 }, // will be repositioned by ParentNode
                  parentNode: props.id,
                  dragHandle: '.custom-drag-handle',
                  extent: 'parent',
                  draggable: false,
                  deletable: false,
                  data: {
                    ...n.data,
                    parent: props.id,
                    baseNodeData: {
                      ...n.data.baseNodeData,
                      hideDetach: true,
                      rotation: Rotation.State.NONE
                    }
                  }
                };
              } // Whenever a child changes, we have to inform the parent as well
              else if (n.id === props.id) {
                n = cloneDeep(n);
                n.data.specificData = {
                  ...n.data.specificData,
                  outputNodeId: theOnlyOutputConditionalNode.id
                } as LogicalBlockParent;
              }
              return n;
            })
          );
        }
      } // 3. There is no conditional node binded to the logical block children:
      // Add conditional node when operator is AND and output not does not exist
      else {
        const contentChildrenNodes = getNodes().filter((n) => contentChildrenIds.includes(n.id));
        const type = NodeType.Conditional;
        const id = `${type}_${generateDoubleId()}`;

        const outputNode: Node = {
          type,
          id,
          position: { x: 0, y: 0 }, // will be repositioned by ParentNode
          dragHandle: '.custom-drag-handle',
          parentNode: props.id,
          extent: 'parent',
          draggable: false,
          deletable: false,
          zIndex: props.zIndex + 1,
          data: {
            ...props.data,
            parent: props.id,
            baseNodeData: {
              ...props.data.baseNodeData,
              hideDetach: true
            }
          }
        };

        // Add the output node
        addNodes(outputNode);
        // If children nodes already exist, remove existing edges and
        deleteElements({ edges: getEdges().filter((e) => contentChildrenIds.includes(e.id)) });
        // Add edges bw it and conditional
        addEdges(contentChildrenNodes.map((n) => createEdgeFrontBetweenNodes(n, outputNode)));
      }
    } // If operator is changing to OR
    else if (operation === LogicalOperator.OR && logicalBlockOutputNodeId) {
      setNodes((ns) =>
        ns
          .map((n) => {
            if (n.id === logicalBlockOutputNodeId) {
              // If output node has specific data set, do not exclude it.
              const specificData = n.data.specificData as CaseData[];
              if (specificData?.length) {
                n = {
                  ...n,
                  parentNode: undefined,
                  extent: undefined,
                  position: { ...n.positionAbsolute! },
                  dragHandle: '.custom-drag-handle',
                  draggable: true,
                  deletable: true,
                  data: {
                    ...n.data,
                    parent: undefined,
                    baseNodeData: {
                      ...n.data.baseNodeData,
                      hideDetach: false
                    }
                  }
                };
                return n;
              }
              // Otherwise, remove output node
              return undefined;
            }
            // Whenever a child changes, we have to inform the parent as well
            else if (n.id === props.id) {
              n = cloneDeep(n);
              n.data.specificData = {
                ...n.data.specificData!,
                outputNodeId: undefined
              } as LogicalBlockParent;
            }
            return n;
          })
          .filter((value): value is Node<CommonNodeData<SpecificDataTypesListable>> => !!value)
      );
    }
  }, [operation]);

  useOnceEffect(() => {
    setIsInit(true);
    return [true];
  });

  return (
    /* Pass data down to base node */
    <ParentNode
      id={props.id}
      selected={props.selected}
      content={{
        titleId: `${$t({
          id: `processFlow.${NodeType.LogicalBlock}`
        })}: ${logicalBlockData.name || `<${$t({ id: 'null' })}>`} (${$t({
          id: `logic.${operation}`
        })})`,
        placeholder: $t({ id: 'logicalBlock.dragToAdd' })
      }}
      handles={handles}
      {...props.data.baseNodeData}
      intersectsWith={props.data.intersectsWith}
      contentChildrenIds={contentChildrenIds}
      outputNodeId={logicalBlockOutputNodeId}
    />
  );
};

export default memo(LogicalBlockNode, arePropsEqual(isCommonNodeDataEqual, isSpecificDataEqual));
