import { cx } from '@emotion/css';
import { Container, TextField, Typography } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import { deburr } from 'lodash';
import React, { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { sendPasswordResetEmailAction } from '../../redux/actions/userActions';
import { updateUi } from '../../redux/reducers/uiReducer';
import LoadingButton from '../widgets/LoadingButton';

const useStyles = appMakeStyles((theme) => ({
  container: {
    ...theme.resizableContainer(4),
    marginTop: theme.spacing(4),
    maxWidth: '1000px'
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  title: {
    color: theme.other.grey.dark,
    marginBottom: theme.spacing(2)
  },
  input: {
    marginBottom: theme.spacing(2)
  }
}));

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();

  const [email, setEmail] = useState('');

  const { ui, locale } = useAppSelector(({ ui, locale }) => ({ ui, locale }));

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!email) {
      dispatch(
        updateUi({
          snackbar: {
            message: { msgCode: '420', msg: 'Invalid email!' },
            severity: 'error',
            show: true
          }
        })
      );

      return;
    }

    dispatch(sendPasswordResetEmailAction({ email, locale: locale.locale }));
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={cx(classes.title)}>
          {$t({ id: 'forgotPw.title' })}
        </Typography>

        <Typography variant="body1" className={cx(classes.title)}>
          {$t({ id: 'forgotPw.subtitle' })}
        </Typography>

        <TextField
          fullWidth
          required
          id="email"
          name="email"
          type="email"
          label={$t({ id: 'forgotPw.input' })}
          value={email}
          onChange={(e) => setEmail(deburr(e.target.value))}
          className={classes.input}
          autoFocus
        />

        <LoadingButton
          isLoading={ui.loadingButton.isForgetPasswordLoading}
          content={$t({ id: 'submit' })}
          type="submit"
        />
      </form>
    </Container>
  );
};

export default ForgotPassword;
