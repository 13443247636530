import { useMemo } from 'react';
import {
  selectCompaniesData,
  selectCompanyExhibitionName
} from 'src/redux/selectors/dataSelectors';
import {
  selectKeyUserCompanies,
  selectCurrentUserProfile
} from 'src/redux/selectors/userSelectors';
import { AppState } from 'src/redux/store';
import { isKeyUserProf } from 'src/util/helpers/user';

/**
 * Returns a map of companies to exhibition names.
 * Is only defined the current user is a Key User.
 */
const useAvailableCompanies = (state: AppState) => {
  const keyUserCompanies = selectKeyUserCompanies(state);
  const companiesData = selectCompaniesData(state);
  const profile = selectCurrentUserProfile(state);
  const currentUserIsKeyUser = isKeyUserProf(profile);

  return useMemo(() => {
    if (currentUserIsKeyUser && companiesData && keyUserCompanies) {
      const companies = keyUserCompanies.reduce((obj, c) => {
        obj[c] = selectCompanyExhibitionName(c, state);
        return obj;
      }, {});
      return companies;
    }
    return undefined;
  }, [currentUserIsKeyUser, companiesData, keyUserCompanies, profile]);
};

export default useAvailableCompanies;
