import { IntlShape } from 'react-intl';
import { Timestamp } from 'firebase/firestore';

export function separateCamelCase(text: string) {
  return (
    text.charAt(0).toUpperCase() +
    text
      .substring(1)
      .replace(/([A-Z])/g, ' $1')
      .trim()
  );
}

export function formatTimeDate(dateOrTimestamp: Date | Timestamp, intl: IntlShape) {
  return intl.formatTime(dateOrTimestamp instanceof Timestamp ? dateOrTimestamp.toDate() : dateOrTimestamp, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
}
