import {
  Checkbox,
  Container,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { isValidId } from 'flyid-core/dist/Util/database';
import { deburr, isEmpty } from 'lodash';
import { FormEvent, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { groupByCompany } from 'src/components/utils/GroupBy';
import LoadingButton from 'src/components/widgets/LoadingButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useGlobalState from 'src/hooks/useGlobalState';
import { createApiKey } from 'src/redux/actions/managementActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectCompanyExhibitionName } from 'src/redux/selectors/dataSelectors';
import {
  selectAuthDomains,
  selectCurrentUserProfile,
  selectTargetCompany,
  selectUserProfiles
} from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { filterModerators } from 'src/util/helpers/user';

const useStyles = appMakeStyles(({ spacing, resizableContainer, other }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0,
    maxWidth: '650px'
  },
  button: {
    margin: spacing(2, 0, 2, 0)
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  input: {
    marginBottom: spacing(2)
  },
  formControl: {
    width: '100%',
    marginBottom: spacing(2)
  },
  text: {
    marginBottom: spacing(2)
  }
}));

const CreateApiKey: React.FC = () => {
  const [description, setDescription] = useState('');
  const [domains, setDomains] = useState<string[]>([]);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();
  const { select } = useAppTheme();

  const { ui, profile, userProfiles, targetCompany, companyName, authDomains } = useAppSelector(
    (s) => {
      const targetCompany = selectTargetCompany(s);
      const companyName = selectCompanyExhibitionName(targetCompany, s);

      return {
        ui: s.ui,
        targetCompany,
        companyName,
        profile: selectCurrentUserProfile(s),
        authDomains: selectAuthDomains(s),
        userProfiles: selectUserProfiles(s)
      };
    }
  );

  const [targetParentUid, setTargetParentUid] = useGlobalState('targetParentUid');

  const handleSubmit = (e: FormEvent) => {    
    e.preventDefault();
    if (!domains?.length || !description || (!!profile?.keyUser && !targetParentUid)) return;
    
    //todo invalid format
    const idIsValid = isValidId(description);
    if (!idIsValid) {
      dispatch(
        updateUi({
          snackbar: {
            message: $t(
              { id: 'validation.idCheckFailed' },
              { target: description, b: (c) => <b>{c}</b> }
            ),
            severity: 'error',
            show: true
          }
        })
      );

      return;
    }

    dispatch(createApiKey({ description, authDomains: domains }));
  };

  const moderators = useMemo(() => {
    if (userProfiles && targetCompany) {
      const companyProfiles = groupByCompany(userProfiles)[targetCompany];
      return filterModerators(companyProfiles);
    }
    return;
  }, [targetCompany, userProfiles]);

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        {profile?.keyUser ? (
          <Typography variant="h4" className={classes.title}>
            {$t({ id: 'createApiKey.kuTitle' }, { companyName })}
          </Typography>
        ) : (
          <Typography variant="h4" className={classes.title}>
            {$t({ id: 'createApiKey.title' })}
          </Typography>
        )}

        {authDomains && !isEmpty(authDomains) ? (
          <>
            <TextField
              fullWidth
              required
              id="description"
              name="description"
              type="text"
              label={$t({ id: 'createApiKey.input' })}
              value={description}
              onChange={(e) => setDescription(deburr(e.target.value))}
              className={classes.input}
              autoFocus
            />

            {profile?.keyUser && moderators ? (
              <FormControl required fullWidth className={classes.formControl}>
                <InputLabel id="parentUid-label">{$t({ id: 'moderator' })}</InputLabel>
                <Select
                  disabled={!targetCompany}
                  labelId="parentUid-label"
                  id="parentUid"
                  name="parentUid"
                  value={targetParentUid}
                  onChange={(e) => setTargetParentUid(e.target.value)}
                  input={<OutlinedInput label={$t({ id: 'parentUid' })} />}
                >
                  {moderators.map((mod) => {
                    return (
                      <MenuItem key={Object.keys(mod)[0]} value={Object.keys(mod)[0]}>
                        <ListItemText
                          primary={`${Object.values(mod)[0].firstName} ${Object.values(mod)[0].lastName}`}
                        />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            ) : null}
            <FormControl className={classes.formControl}>
              <InputLabel id="authDomains-select-label">
                {$t({ id: 'editApiKeyAuthDom.authDomains' })}
              </InputLabel>
              <Select
                labelId="authDomains-select-label"
                id="authDomains-select"
                multiple
                value={domains}
                onChange={(e) => setDomains(e.target.value as string[])}
                input={<OutlinedInput label={$t({ id: 'editApiKeyAuthDom.authDomains' })} />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={select.getMenuProps()}
              >
                {authDomains.map((domain) => (
                  <MenuItem key={domain} value={domain}>
                    <Checkbox checked={domains.indexOf(domain) > -1} />
                    <ListItemText primary={domain} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <Typography className={classes.text}>
            {$t({ id: 'editApiKeyAuthDom.noDomainsAvailable' })}
          </Typography>
        )}

        <LoadingButton
          isLoading={ui.loadingButton.isCreateApiKeyLoading}
          content={$t({ id: 'createApiKey.title' })}
          type="submit"
        />
      </form>
    </Container>
  );
};

export default CreateApiKey;
