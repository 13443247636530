import { FirebaseApp } from 'firebase/app';
import { Auth, onAuthStateChanged, Unsubscribe, User } from 'firebase/auth';
import {
  documentId,
  Firestore,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  where
} from 'firebase/firestore';
import { FirebaseStorage } from 'firebase/storage';
import { migrateSettings } from 'flyid-core/dist/Database/Migration/settingsMigration';
import { APIKey, License } from 'flyid-core/dist/Database/Models';
import {
  getApiKeysCol,
  getCompaniesCol,
  getCompanyDoc,
  getCustomAuthenticationSettingsDoc,
  getDomainsCol,
  getDomainSettingsCol,
  getOurLicensesCol,
  getUserProfileDoc,
  getUsersCol
} from 'flyid-core/dist/Util/database';
import {
  setAuthProvider,
  updateAPIKeys,
  updateAuthDomains,
  updateAuthLicenses,
  updateCompaniesData,
  updateDomainSettings,
  updateUserProfiles
} from 'src/redux/reducers/firestoreReducer';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { setUserProfile, setUserProfileError } from 'src/redux/reducers/userReducer';
import {
  selectAuthDomains,
  selectCurrentUserProfile,
  selectTargetCompany
} from 'src/redux/selectors/userSelectors';
import type { AppStore } from 'src/redux/store';
import { isKeyUserProf, isModeratorProf } from 'src/util/helpers/user';
import { Nilable } from 'tsdef';
import {
  buildCollectionRef,
  buildDocumentRef,
  getDividedQueries,
  querySnapToMap
} from './firestore';
// COR - Set to false
const DEBUG_LISTENERS = false;

export type UpdateListenersData = { company: string; state: Nilable<ListenersState> };
export type UnsubscribeListeners = { [company: string]: Nilable<Array<keyof ListenersState>> };

type Unsubs = Unsubscribe | Unsubscribe[];
/** Keeps track of data listeners, if Unsubs exists, then listeners have been set. */
export type ListenersState = {
  domainSettings?: Unsubs;
  userProfiles?: Unsubs;
  authLicenses?: Unsubs;
  apiKeys?: Unsubs;
  companyData?: Unsubs;
  authDomains?: Unsubs;
};

let listenersStatePerCompany: { [company: string]: ListenersState | undefined } = {};

const updateListenersState = (company: string, state: Nilable<ListenersState>) => {
  // console.log(`Updating listeners of ${company}:`, state);
  listenersStatePerCompany[company] = { ...listenersStatePerCompany[company], ...state };
};

const cancelListener = (unsubs: Nilable<Unsubs>) =>
  Array.isArray(unsubs) ? unsubs.forEach((unsub) => unsub()) : unsubs?.();

/** Send null payload to unsubscribe all */
const unsubscribeListeners = (data: UnsubscribeListeners | null) => {
  // Null payload will trigger complete cleanup of listeners
  if (!data) {
    Object.values(listenersStatePerCompany).forEach((companyListeners) => {
      if (companyListeners) Object.values(companyListeners).forEach(cancelListener);
    });
    listenersStatePerCompany = {};
  } else {
    // Payload should lead to specific listener cancellation
    Object.entries(data).forEach(([company, listenersData]) => {
      // If no specific listener is given, cancel all company's listeners.
      if (!listenersData) {
        Object.values(listenersStatePerCompany[company] ?? {}).forEach(cancelListener);
        listenersStatePerCompany[company] = {};
      } else {
        // Otherwise, cancel only the specifics ones
        listenersData.forEach((target) => {
          const companyListeners = listenersStatePerCompany[company];
          if (companyListeners) {
            const targetListener = companyListeners[target];
            if (targetListener) cancelListener(targetListener);

            companyListeners[target] = undefined;
          }
        });
      }
    });
  }
};

/** This fetch should happen once, when the app starts */
const fetchAuthSettings = (store: AppStore) => {
  // This document is public, therefore we don't need to wait for auth
  if (DEBUG_LISTENERS) console.log('Fetching authentication settings...');
  getDoc(buildDocumentRef(getCustomAuthenticationSettingsDoc()))
    .then((authSettingsDS) => {
      store.dispatch(setAuthProvider(authSettingsDS.data()?.provider));
    })
    .catch((error) => console.error(`Failed fetching auth providers: (${error.message})`));
};

let userDataListenerUnsubscriber: Unsubscribe | null = null;
/** This listener should be set once the user logs in. */
const listenToCurrentUserProfile = (user: User, store: AppStore, auth: Auth) => {
  userDataListenerUnsubscriber?.(); // Subscribe only once.
  if (DEBUG_LISTENERS) console.log('Adding current user data listener...');
  const { uid, emailVerified } = user;
  userDataListenerUnsubscriber = onSnapshot(buildDocumentRef(getUserProfileDoc(uid)), {
    next: (newUserProfile) => {
      user
        ?.getIdTokenResult(true)
        ?.then((userToken) => {
          if (!newUserProfile.exists()) throw new Error('Missing user profile!');
          if (!userToken) throw new Error('Missing user profile!');

          const state = store.getState();
          const previousProfile = selectCurrentUserProfile(state);
          // Whenever profile changes, make sure to reset domain settings listener,
          // since it requires refreshing due to possible changes to authDomains for non-key-users.
          // Key users will always listen to all targetCompany's domains, therefore no action is required.
          if (previousProfile && !isKeyUserProf(previousProfile)) {
            const company = previousProfile!.company! as string;
            if (DEBUG_LISTENERS)
              console.log(`Resetting domain settings listener state for ${company}`);
            // Unsubscribe listeners for old profile
            unsubscribeListeners({ [company]: ['domainSettings'] });
          }

          store.dispatch(
            setUserProfile({
              uid,
              emailVerified,
              profile: newUserProfile.data(),
              claims: userToken.claims as never
            })
          );
        })
        .catch((error: Error) => {
          console.error(error);
          store.dispatch(setUserProfileError({ uid, error }));
          store.dispatch(updateUi());
        });
    },
    error: (error) => console.error(`Failed fetching user profiles: (${error.message})`)
  });
};

const listenToManagementData = (store: AppStore) => {
  const state = store.getState();
  const { profile, uid, claims, isLoaded } = state.user;

  const isKeyUser = isKeyUserProf(profile);
  const isModerator = isModeratorProf(profile);
  const targetCompany = selectTargetCompany(state);

  if ((isKeyUser || isModerator) && uid && claims && isLoaded && targetCompany) {
    const companyListeners = listenersStatePerCompany[targetCompany];
    // User Profiles
    if (!companyListeners?.userProfiles) {
      if (DEBUG_LISTENERS)
        console.log(`Adding public profiles listener for company ${targetCompany}...`);
      let usersQuery = query(
        buildCollectionRef(getUsersCol()),
        where('company', '==', targetCompany)
      );
      if (isModerator) usersQuery = query(usersQuery, where('parent', '==', uid));

      const unsubscriber = onSnapshot(usersQuery, {
        next: (usersQS) => store.dispatch(updateUserProfiles(querySnapToMap(usersQS))),
        error: (error) => console.error(`Failed fetching user profiles: (${error.message})`)
      });
      updateListenersState(targetCompany, { userProfiles: unsubscriber });
    }

    // Auth Licenses
    if (!companyListeners?.authLicenses) {
      if (DEBUG_LISTENERS)
        console.log(`Adding auth licenses listener for company ${targetCompany}...`);
      const authLicensesRef = buildCollectionRef(getOurLicensesCol());

      let unsubscribe: Unsubs;
      const setSnapshot = (query: Query<License>) =>
        onSnapshot(query, {
          next: (licensesQS) => store.dispatch(updateAuthLicenses(querySnapToMap(licensesQS))),
          error: (error) => console.error(`Failed fetching auth licenses: (${error.message})`)
        });

      if (isModerator) {
        // Moderators have specific access.
        const lics = profile.authLicenses ?? [];
        unsubscribe = getDividedQueries(authLicensesRef, documentId(), 'in', lics).map(setSnapshot);
      } else {
        // If not mod, then is certainly key user, which has unrestricted company-based access
        unsubscribe = setSnapshot(query(authLicensesRef, where('company', '==', targetCompany)));
      }

      updateListenersState(targetCompany, { authLicenses: unsubscribe });
    }

    // API Keys
    if (!companyListeners?.apiKeys) {
      if (DEBUG_LISTENERS) console.log(`Adding API Keys listener for company ${targetCompany}...`);
      const apiKeysRef = buildCollectionRef(getApiKeysCol());

      let unsubscribe: Unsubs;
      const setSnapshot = (query: Query<APIKey>) =>
        onSnapshot(query, {
          next: (apiKeysQS) => store.dispatch(updateAPIKeys(querySnapToMap(apiKeysQS))),
          error: (error) => console.error(`Failed fetching API Keys: (${error.message})`)
        });

      if (isModerator) {
        // Moderators have specific access.
        const keys = claims.ownedAPIKeys ?? [];
        unsubscribe = getDividedQueries(apiKeysRef, documentId(), 'in', keys).map(setSnapshot);
      } else {
        // If not mod, then is certainly key user, which has unrestricted company-based access
        unsubscribe = setSnapshot(query(apiKeysRef, where('company', '==', targetCompany)));
      }

      updateListenersState(targetCompany, { apiKeys: unsubscribe });
    }

    // Company data
    if (!companyListeners?.companyData) {
      if (DEBUG_LISTENERS) console.log(`Adding company data listener for ${profile.company}...`);

      let unsubscribe: Unsubs;
      if (isKeyUser) {
        unsubscribe = getDividedQueries(
          buildCollectionRef(getCompaniesCol()),
          documentId(),
          'in',
          profile.company
        ).map((query) =>
          onSnapshot(query, {
            next: (qs) => store.dispatch(updateCompaniesData(querySnapToMap(qs))),
            error: (error) => console.error(`Failed fetching company data: (${error.message})`)
          })
        );
      } else {
        unsubscribe = onSnapshot(buildDocumentRef(getCompanyDoc(targetCompany)), {
          next: (docSnap) =>
            store.dispatch(updateCompaniesData({ [targetCompany]: docSnap.data()! })),
          error: (error) => console.error(`Failed fetching company data: (${error.message})`)
        });
      }
      updateListenersState(targetCompany, { companyData: unsubscribe });
    }

    // Auth Domains, if key user
    if (isKeyUser && !companyListeners?.authDomains) {
      if (DEBUG_LISTENERS) console.log(`Adding auth domains listener for ${targetCompany}...`);
      const unsubscribe = onSnapshot(buildCollectionRef(getDomainsCol(targetCompany)), {
        next: (domainsQS) =>
          store.dispatch(updateAuthDomains({ [targetCompany]: domainsQS.docs.map((s) => s.id) })),
        error: (error) => console.error(`Failed domains data: (${error.message})`)
      });

      updateListenersState(targetCompany, { authDomains: unsubscribe });
    }
  }
};

/**
 * Listens to domain settings data.
 * This listener is careful enough to trigger only once, unless
 */
const listenToDomainSettingsData = (store: AppStore) => {
  const state = store.getState();

  const targetCompany = selectTargetCompany(state);
  const authDomains = selectAuthDomains(state);

  if (
    targetCompany &&
    authDomains?.length &&
    !listenersStatePerCompany[targetCompany]?.domainSettings
  ) {
    if (DEBUG_LISTENERS) {
      console.log(`Adding domain settings listener for ${targetCompany}/${authDomains}...`);
    }

    const unsubscribers = authDomains.map((domain) =>
      onSnapshot(
        query(
          buildCollectionRef(getDomainSettingsCol(targetCompany, domain)),
          orderBy('createdDate', 'desc'),
          limit(1)
        ),
        {
          next: (settingsQS) => {
            if (settingsQS.empty) {
              console.error(`No domain settings for domain ${targetCompany}/${domain}!`);
              return;
            }
            store.dispatch(
              updateDomainSettings({ [domain]: migrateSettings(settingsQS.docs[0].data()) })
            );
          },
          error: (error) => console.error(`Failed fetching domain settings: (${error.message})`)
        }
      )
    );
    updateListenersState(targetCompany, { domainSettings: unsubscribers });
  }
};

// Triggers these actions whenever auth state changes
function setupOnAuthStateListener(store: AppStore, auth: Auth) {
  onAuthStateChanged(auth, (user) => {
    const isLoggedIn = !!user;
    if (isLoggedIn) {
      listenToCurrentUserProfile(user, store, auth);
    } else {
      setUserProfile(null);
    }
  });
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export function setupListeners({
  store,
  firebaseApp,
  auth,
  firestore,
  defaultBucket,
  profilePicsBucket
}: /* eslint-enable @typescript-eslint/no-unused-vars */
{
  store: AppStore;
  firebaseApp: FirebaseApp;
  auth: Auth;
  firestore: Firestore;
  defaultBucket: FirebaseStorage;
  profilePicsBucket: FirebaseStorage;
}) {
  setupOnAuthStateListener(store, auth);
  // Listeners to be set once
  fetchAuthSettings(store);

  // Listeners that may change, given global state changes.
  // The listeners  contained in 'subscribe' should care about their own listening states in order
  // to avoid multiplicity of listeners.
  store.subscribe(() => {
    if (!!auth.currentUser) {
      listenToManagementData(store);
      listenToDomainSettingsData(store);
    }
  });
}
