import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, getFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import environment from 'src/environment';
import fbConfig from '../util/config';

// Initialize firebase instance
const firebaseApp: FirebaseApp = initializeApp(fbConfig);
const auth: Auth = getAuth(firebaseApp);
const firestore: Firestore = getFirestore(firebaseApp);
const defaultBucket: FirebaseStorage = getStorage(firebaseApp);
const profilePicsBucket: FirebaseStorage = getStorage(
  firebaseApp,
  process.env.REACT_APP_PROFILE_PICS_BUCKET
);

if (environment.usingEmulators) {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

// Get firebase references for store
const initFirebase = () => ({ firebaseApp, auth, firestore, defaultBucket, profilePicsBucket });

export { auth, defaultBucket, firebaseApp, firestore, initFirebase, profilePicsBucket };
