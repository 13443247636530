import {
  EdgeTypes,
  FlowEdge,
  getNodeTypeFromStringValue,
  HandleType,
  NodeType
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { getTypedElementId } from 'flyid-core/dist/Util/processFlow';
import { ComponentType } from 'react';
import { Edge, EdgeProps, Node } from 'reactflow';
import CustomEdge from 'src/components/management/domainsettings/processflow/edges/CustomEdge';
import { getPureId } from './common';
import { FlowEdgeFront } from './types';
import { ProcessFlowSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';

export const edgeTypes: Record<EdgeTypes, ComponentType<EdgeProps>> = {
  [EdgeTypes.Custom]: CustomEdge
};

export const createEdgeFrontBetweenNodes = (sourceNode: Node, targetNode: Node) =>
  createEdgeFrontBetween(
    getPureId(sourceNode.id)!,
    getNodeTypeFromStringValue(sourceNode.type)!,
    getPureId(targetNode.id)!,
    getNodeTypeFromStringValue(targetNode.type)!
  );

export const createEdgeFrontBetween = (
  sourceNodeId: string,
  sourceNodeType: NodeType,
  targetNodeId: string,
  targetNodeType: NodeType,
  sourceHandle = `${HandleType.SINGLE}_0`,
  targetHandle = `${HandleType.MULTIPLE}_0`,
  type: 'default' | 'step' | 'smoothstep' | 'straight' | EdgeTypes = EdgeTypes.Custom
): FlowEdgeFront => {
  const source = getTypedElementId(sourceNodeId, sourceNodeType);
  const target = getTypedElementId(targetNodeId, targetNodeType);
  return {
    id: `reactflow__edge-${source}${sourceHandle}-${target}${targetHandle}`,
    source,
    target,
    sourceHandle,
    targetHandle,
    type: type as EdgeTypes
  };
};

export const settToFrontEdges = (settings: ProcessFlowSettings): FlowEdgeFront[] => {
  const nodes = settings.nodes;
  const edges = settings.edges;

  return edges.map((e) => {
    const source = getTypedElementId(e.source, nodes[e.source].type);
    const target = getTypedElementId(e.target, nodes[e.target].type);

    return {
      id: `reactflow__edge-${source}${e.sourceHandle}-${target}${e.targetHandle}`,
      source,
      target,
      sourceHandle: e.sourceHandle,
      targetHandle: e.targetHandle,
      type: EdgeTypes.Custom
    };
  });
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const frontToSettEdges = (edges: Edge[]): FlowEdge[] => {
  return edges.map((e) => ({
    source: getPureId(e.source)!,
    target: getPureId(e.target)!,
    sourceHandle: e.sourceHandle!,
    targetHandle: e.targetHandle!
  }));
};
/* eslint-enable @typescript-eslint/no-non-null-assertion */
